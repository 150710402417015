import React from 'react';
import HeroSection from './About/HeroSection';
import TeamSection from './About/TeamSection';
import StatisticsSection from './About/StorySection';
import MissionStatement from './About/MessionStatement';
import FAQSection from './About/FaqSection';
import { AboutUsSection } from './About/AboutusSection';
import CallToAction from './About/CallToAction';

const About = () => {
  return (
    <> 
      <HeroSection />
      <AboutUsSection />
      <StatisticsSection />
      {/* <TeamSection />  */}
      <CallToAction />
    </>
  );
};

export default About;
