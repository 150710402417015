import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../Common/CartContext';
import { FILE_URL } from '../../../redux/env'; 
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'; // Import Tooltip from react-tooltip
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for the tooltip

const ProjectPage = ({ projectDetails, projectCodeList }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { addToCart } = useContext(CartContext);

  const [selectedCode, setSelectedCode] = useState({
    id: projectCodeList.length > 0 && projectCodeList[0].id,
    title: projectCodeList.length > 0 && projectCodeList[0].title,
    price_regular_offer: projectDetails && projectDetails.price_regular_offer,
    price_regular: projectDetails && projectDetails.price_regular,
  });

  useEffect(() => {
    setSelectedCode({
      id: projectCodeList.length > 0 && projectCodeList[0].id,
      title: projectCodeList.length > 0 && projectCodeList[0].title,
      price_regular_offer: projectDetails && projectDetails.price_regular_offer,
      price_regular: projectDetails && projectDetails.price_regular,
    });
  }, [projectDetails]);

  const handleLicenseSelect = (codelist) => {
    setSelectedCode({
      title: codelist.title,
      price_regular_offer: codelist.price_regular_offer,
      price_regular: codelist.price_regular
    });
    setDropdownOpen(false);
  };

  const handleAddToCart = (id, name, version) => {
    addToCart({
      id: id,
      name: name,
      license: "Regular License",
      title: selectedCode.title,
      price: selectedCode.price_regular_offer,
      oldPrice: selectedCode.price_regular,
      version: version.version,
      version_id: version.id,
      content_id: selectedCode.id,
    });
  };

  const handleBuyNow = (id, name, version) => {
      handleAddToCart(id, name, version);
    navigate('/checkout');
  }

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-6 lg:px-8">
        {projectDetails && 
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <div className="relative group p-4 rounded-xl shadow-xl ring-1 ring-gray-300/10 bg-gradient-to-r from-blue-100 to-gray-200">
            <img src={FILE_URL + projectDetails.image} alt="Generative AI Solutions" className="w-full " />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl cursor-pointer">
              <div className="text-white">
                <img src="/images/preview_icon.png" alt="Play" className="w-24 h-24  mx-auto" />
                <p className="mt-2 text-lg font-semibold mx-auto">Live Preview</p>
              </div>
            </div>
            <div className="bg-white border-t-2 border-gray-300 mx-auto text-center py-8">
              <span className="text-sm bg-gray-500 text-white px-8 py-2 rounded-full font-semibold">Live Preview</span>
            </div>
          </div>
          <div className="lg:pl-8">
            <div className="max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{projectDetails.name}</h2>
              <div className='md:flex'>
                <h2 className="font-semibold mt-2 tracking-tight text-gray-400 ">Vers {projectDetails.latest_version?.version}.0</h2>
                
                <Link
                  to="/regular-license/"
                  data-tooltip-id="licenseTooltip"
                  data-tooltip-content="Use, by you or one client, in a single end product which end users are not charged for. The total price includes the item price and a buyer fee."
                  className="ml-8 mt-2 tracking-tight text-blue-500"
                >
                  <b>License</b> - Regular License
                </Link>
                <Tooltip 
                  id="licenseTooltip" 
                  place="bottom" 
                  effect="solid" 
                  style={{ color: "white", padding: "10px", borderRadius: "10px", width: "400px" }}
                />
              </div>
              <p className="mt-2 text-lg leading-8 text-gray-700">
                {projectDetails.description}
              </p> 
              <div className="flex flex-wrap max-w-full mt-4 text-gray-700">
                {projectDetails.technology && (projectDetails.technology).split(',').map((tech, index) => (
                  <p key={index} className="bg-gradient-to-r from-blue-100 to-gray-200 font-semibold mx-2 my-2 md:my-0 py-1 px-2 rounded-full text-xs md:text-sm">{tech}</p>
                ))}
              </div>
              <div className="mt-6 relative">
                <button
                  className="flex items-center justify-between w-3/4 bg-gray-50 border border-gray-100 rounded-lg px-4 py-3 text-gray-900"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <div className="flex items-center">
                    <span className="text-gray-700 font-semibold">{selectedCode.title}</span>
                    <svg
                      className={`ml-2 w-5 h-5 transform transition-transform ${dropdownOpen ? 'rotate-180' : ''}`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                  <div className="flex items-base">
                    {selectedCode.price_regular && (
                      <span className="text-md text-gray-500 font-semibold line-through mr-2">${selectedCode.price_regular}</span>
                    )}
                    <span className="text-xl font-bold text-blue-600">${selectedCode.price_regular_offer}</span>
                  </div>
                </button>
                {dropdownOpen && (
                  <div className="absolute z-10 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                    {projectCodeList && projectCodeList.map((codelist, index) => (
                      <div
                        key={index}
                        className="p-4 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleLicenseSelect({
                          id: codelist.id,
                          title: codelist.title,
                          price_regular_offer: projectDetails.price_regular_offer,
                          price_regular: projectDetails.price_regular
                        })}
                      >
                        <div className="flex justify-between items-center">
                          <span className="font-semibold">{codelist.title}</span> 
                          <span className="text-xl font-bold text-blue-600">{projectDetails.price_regular && (
                            <span className="text-sm text-gray-500 font-semibold line-through mr-2">${projectDetails.price_regular}</span>
                          )} ${projectDetails.price_regular_offer}</span>
                        </div>
                      </div>
                    ))}
                    {/* <div className="p-4 text-center">
                      <a href="https://codecanyon.net/licenses/standard" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                        View license details
                      </a>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
              <button onClick={() => handleBuyNow(projectDetails.id, projectDetails.name, projectDetails.latest_version)} className="group px-8 inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold border border-blue-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
                Buy Now
              </button>
              <button onClick={()=>handleAddToCart(projectDetails.id, projectDetails.name, projectDetails.latest_version)} className="group px-8 inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 text-blue-600 border bg-white border-blue-600 hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 hover:bg-blue-600 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
                Add to Cart
              </button>
            </div>
          </div>
        </div>}
      </div>
    </section>
  );
};

export default ProjectPage;
