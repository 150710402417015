import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaDownload, FaBoxOpen } from 'react-icons/fa';
import { IoDocumentTextOutline } from "react-icons/io5";
import { downloadProject, purchasedProject } from '../../../redux/actions/projectActions';
import { FILE_URL } from '../../../redux/env';

const Download = () => {
  const [projects, setProjects] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(purchasedProject())
      .then((data) => {
        setProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching user orders:", error);
      });
  }, [dispatch]);

  const handleDownload = (content) => {
    dispatch(downloadProject(content)).then((data) => {
      setShowMessage(true);
    });
  }

  const handleDocument = (content_id) => {
    navigate('/document', { state: { content_id: content_id } });
  }

  return (
    <div className="container mx-auto p-4">
      {showMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md mx-auto text-center"> 
            <h2 className="text-2xl font-semibold mb-4">Download Link Sent</h2>
            <p className="text-gray-600 mb-4">
              A download link has been sent to your registered email. This link is valid for a single download.
            </p>
            <button
              onClick={() => setShowMessage(false)}
              className="inline-flex items-center justify-center rounded-full py-2 px-8 text-md font-semibold bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {projects && projects.length > 0 ? (
        projects.map((project) => (
          <div key={project.id} className="relative flex flex-col mx-auto md:flex-row mb-6 bg-blue-50 p-4 rounded-lg shadow-md">
            <div className="flex items-center md:w-2/3">
              <div className="rounded-lg w-32 md:w-1/3 border-2 border-gray-300">
                <img className='rounded-lg' src={FILE_URL + project.project_image} alt={project.name} />
              </div>
              <div className="md:w-3/4 pl-8">
                <h4 className="text-lg font-semibold">{project.project_name}</h4>
                <p className="text-gray-500 font-semibold my-1">
                  License: <span className="text-blue-700">{project.license}</span>
                </p>
                <p className="text-gray-500 font-semibold my-1">
                  Language: <span className="text-blue-700">{project.project_code}</span>
                </p>
                <p className="text-gray-500 font-semibold my-1">
                  Version: <span className="text-blue-700">{project.version}</span>
                </p> 
              </div>
            </div>
            <div className='items-center justify-center md:justify-left'>
              <div className="flex mt-2 mb-4">
                <button onClick={() => handleDownload(project.content)}
                  className="group inline-flex items-center justify-center rounded-full py-2 px-8 mt-8 md:mt-0 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
                  <FaDownload className="mr-2" />
                  Download
                </button>
              </div>
              <div className="flex">
                <button onClick={() => handleDocument(project.content)}
                  className="group inline-flex items-center justify-center rounded-full py-2 px-8 mt-8 md:mt-0 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white border border-blue-600 text-blue-600 hover:bg-gradient-to-r hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
                  <IoDocumentTextOutline size={20} className="mr-2" />
                  Document 
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-10">
          <FaBoxOpen className="text-gray-400 text-9xl mx-auto mb-8" />
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">No Projects Found</h3>
          <p className="text-gray-500 mb-6">It looks like you don't have any projects yet. Explore our collection and find the perfect project for you!</p>
          <Link to="/" className="group inline-flex items-center justify-center rounded-full py-2 px-8 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
            Browse Projects
          </Link>
        </div>
      )}
    </div>
  );
};

export default Download;
