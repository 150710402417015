import React from 'react';
import InvoAiHero from './InvoAiHero';
import InvoAiService from './InvoAIService';
import InvoAiFaq from './InvoAiFaq';
import Divider from './Divider';

const InvoAi = () => {
  return (
    <> 
      <InvoAiHero />
      <InvoAiService />
      <Divider />
      <InvoAiFaq />
    </>
  );
};

export default InvoAi;
