import React, { createContext, useState, useEffect } from 'react';
import { encryptData, decryptData } from '../utils/encryption';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  useEffect(() => {
    const storedCart = localStorage.getItem('R#W42FG*FG');
    if (storedCart) {
      const decryptedCart = decryptData(storedCart);
      if (decryptedCart) {
        setCart(decryptedCart);
      } else {
        console.error('Failed to decrypt cart data');
      }
    }
    setIsCartLoaded(true); // Mark cart as loaded
  }, []);

  useEffect(() => {
    if (isCartLoaded) {
      const encryptedCart = encryptData(cart);
      if (encryptedCart) {
        localStorage.setItem('R#W42FG*FG', encryptedCart);
      } else {
        console.error('Failed to encrypt cart data');
      }
    }
  }, [cart, isCartLoaded]);

  const addToCart = (newItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(item => item.id === newItem.id);
      if (existingItem) {
        return prevCart; // Do not add duplicate items
      } else {
        return [...prevCart, { ...newItem, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter(item => item.id !== id));
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
