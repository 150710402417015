import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBlog } from '../../../redux/actions/projectActions';
import { FILE_URL } from '../../../redux/env';
import CallToActionSection from '../Home/Calltoactionsection';

const Blog = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);

    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getBlog(slug)).then((data) => {
        setBlog(data);
      });
    }, [dispatch]);

    if (!blog) return <div>Loading...</div>;

    return (
        <div className="border-t pt-4 md:pt-8">
            <div className="container max-w-4xl mx-auto p-4">
                <div className="gap-8 mb-12">
                    
                    {/* Main Content */}
                    <div className="mb-8">
                        {/* Featured Image */}
                        {blog.image && (
                            <div className="mb-6">
                                <img
                                    src={FILE_URL + blog.image}
                                    alt={blog.title}
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>
                        )}

                        {/* Title and Meta Information */}
                        <h1 className="text-4xl font-bold mb-4 text-gray-900">{blog.title}</h1>
                        <div className="flex items-center mb-6">
                            <img
                                src={FILE_URL + blog.author_image}
                                alt={blog.author_name}
                                className="w-12 h-12 rounded-full mr-4"
                            />
                            <div>
                                <p className="text-lg font-medium text-gray-800">{blog.author_name}</p>
                                <p className="text-gray-500">{new Date(blog.created_at).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}</p>
                            </div>
                        </div>

                        {/* Blog Content */}
                        <div className="prose prose-lg text-gray-800 mb-4" dangerouslySetInnerHTML={{ __html: blog.content }}></div>

                        {blog.image2 && <img
                            src={FILE_URL + blog.image2}
                            alt={blog.author_name}
                            className="w-full max-h-screen rounded-lg shadow-lg mb-8"
                        />}
                        <div className="prose prose-lg text-gray-800 mb-8" dangerouslySetInnerHTML={{ __html: blog.content2 }}></div>
                        {blog.image3 && <img
                            src={FILE_URL + blog.image3}
                            alt={blog.author_name}
                            className="w-full max-h-screen rounded-lg shadow-lg mb-8"
                        />}
                        <div className="prose prose-lg text-gray-800 mb-8" dangerouslySetInnerHTML={{ __html: blog.content3 }}></div>
                        {blog.image4 && <img
                            src={FILE_URL + blog.image4}
                            alt={blog.author_name}
                            className="w-full max-h-screen rounded-lg shadow-lg mb-8"
                        />}
                        <div className="prose prose-lg text-gray-800 mb-8" dangerouslySetInnerHTML={{ __html: blog.content4 }}></div>
                    </div>



                    {/* Sidebar */}
                    <div className="space-y-8 mb-8">
                        
                        {/* Popular Articles */}
                        {/* <div className="bg-white shadow-lg rounded-lg p-6">
                            <h2 className="text-xl font-bold mb-4">Popular Articles</h2>
                            <ul className="space-y-4">
                                {blog.popularArticles && blog.popularArticles.map(article => (
                                    <li key={article.id} className="flex items-start">
                                        <img src={FILE_URL + article.image} alt={article.title} className="w-16 h-16 object-cover rounded-lg mr-4" />
                                        <div>
                                            <a href={`/blogs/${article.slug}`} className="text-md font-semibold text-pink-600 hover:underline">{article.title}</a>
                                            <p className="text-gray-500 text-sm">{new Date(article.created_at).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                    </div>
                    <div className="justify-center text-center mb-16">
                        <button className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-blue-700"> View All Blogs </button>
                    </div>
                </div>
                <CallToActionSection />
            </div>
        </div>
    );
};

export default Blog;
