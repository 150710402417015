import React from "react";

const ProjectReview = ({ reviews }) => { 

    return (
        <section>
            <div className="container mx-auto px-6 lg:px-8">
                <div className="mt-8">
                    <p className="font-semibold text-gray-700 mb-4">See what our users are saying about our solutions.</p>
                    {reviews.map((review, index) => (
                        <div key={index} className="bg-white p-4 rounded-lg shadow mb-4">
                            <div className="flex items-center">
                                {[...Array(5)].map((_, i) => (
                                    <svg key={i} className={`w-5 h-5 ${i < review.rate ? 'text-yellow-500' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M9.049.293a1 1 0 011.902 0l1.17 3.584a1 1 0 00.95.692h3.764a1 1 0 01.588 1.81l-3.043 2.216a1 1 0 00-.364 1.118l1.17 3.585a1 1 0 01-1.538 1.118L10 11.849l-3.043 2.216a1 1 0 01-1.538-1.118l1.17-3.585a1 1 0 00-.364-1.118L3.182 6.379a1 1 0 01.588-1.81h3.764a1 1 0 00.95-.692L9.049.293z" />
                                    </svg>
                                ))}
                            </div>
                            <p className="mt-2 text-gray-700">{review.comment}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ProjectReview