import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const BlogByCategory = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        fetch('/api/blogs/')
            .then(response => response.json())
            .then(data => setBlogs(data));
    }, []);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Blog List</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {blogs.map(blog => (
                    <div key={blog.id} className="p-4 bg-white shadow-md rounded-lg">
                        <h2 className="text-xl font-semibold">{blog.title}</h2>
                        <p className="text-gray-600">{blog.author}</p>
                        <Link to={`/blogs/${blog.slug}`} className="text-blue-500 hover:underline">Read More</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogByCategory;
