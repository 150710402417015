import React, { useState, useEffect, useRef } from 'react';
import ChatInput from './ChatInput';
import Message from './Message';

const ChatBox = ({ project, messages = [], addMessage }) => {
    const [filteredMessages, setFilteredMessages] = useState([]);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (Array.isArray(messages)) {
            // Assuming `order` is the field in messages corresponding to project ID
            const projectMessages = messages.filter(msg => msg.order === parseInt(project));
            setFilteredMessages(projectMessages);
        } else {
            setFilteredMessages([]);
        }
    }, [project, messages]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [filteredMessages]);

    console.log('filteredMessages:', filteredMessages);

    return (
        <div className="flex flex-col h-full">
            <div 
                ref={chatContainerRef} 
                className="flex-grow p-4 h-96 overflow-y-auto"
                style={{ maxHeight: 'calc(100vh - 160px)' }} 
            >
                {filteredMessages.length > 0 ? (
                    filteredMessages.map((msg, index) => (
                        <Message key={index} message={msg} />
                    ))
                ) : (
                    <p className="text-gray-500">No messages yet. Start the conversation!</p>
                )}
            </div>
            <ChatInput onSendMessage={(msg) => addMessage(msg, project)} />
        </div>
    );
};

export default ChatBox;
