import React from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const HomePageHero = () => {
  return (
    <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 pb-16 pt-20 text-center lg:pt-32 bg-gradient-to-r from-blue-50 via-white to-white">
      <h1 className="mx-auto max-w-4xl font-display text-4xl sm:text-5xl lg:text-6xl xl:text-7xl leading-snug sm:leading-tight lg:leading-loose font-bold tracking-tight text-slate-900">
        Transform Your Business with{' '}
        <span className="relative whitespace-nowrap text-blue-600">
  <svg
    aria-hidden="true"
    viewBox="0 0 418 42"
    className="absolute left-0 top-2/3 h-[0.5em] w-full"
    preserveAspectRatio="none"
  >
    <defs>
      <linearGradient id="gradient" x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" stopColor="#6347e5" />
        <stop offset="100%" stopColor="#3b82f6" />
      </linearGradient>
      <text id="text" x="50%" y="50%" dy=".35em" textAnchor="middle" fill="url(#gradient)">
        GenAI
      </text>
    </defs>
    <path d="M0 28 H418 V34 H0z M0 34 H418 V40 H0z" fill="url(#gradient)" />
  </svg>
  <span className="relative" style={{ background: 'linear-gradient(90deg, #6347e5, #3b82f6)', WebkitBackgroundClip: 'text', color: 'transparent' }}>
    GenAI
  </span>
</span>

        {' '}
        Solutions
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-base sm:text-lg lg:text-xl font-semibold tracking-tight text-slate-700">
        Empower your business with cutting-edge generative AI projects. Extract, analyze, and visualize data effortlessly with GenAI.
      </p>
      <div className="mt-10 flex flex-col sm:flex-row justify-center gap-4 sm:gap-6">
        <Link
          className="group inline-flex items-center justify-center rounded-full py-3 px-6 text-sm sm:text-base font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 shadow-lg transform transition-transform hover:scale-105 active:scale-95"
          to="/signup"
        >
          <span className="">Get Started Today</span>
        </Link>
        <Link
          className="flex items-center justify-center rounded-full py-3 px-6 text-sm sm:text-base font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gray-100 text-slate-900 hover:bg-gray-200 hover:text-slate-800 active:bg-gray-300 active:text-slate-700 focus-visible:outline-gray-100 shadow-lg transform transition-transform hover:scale-105 active:scale-95"
          to="/contact"
        >
          <span className="mr-2">Book a Demo</span> <FaArrowRight className='mt-1' />
        </Link>
      </div>
    </div>
  );
};

export default HomePageHero;
