import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { encryptData, decryptData } from '../../../utils/encryption';
import { updateProfile } from '../../../redux/actions/authActions';
import axiosInstance from '../../../Common/axiosInstance';

const ProfileSettings = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const responseCountry = await fetch('https://restcountries.com/v3.1/all');
        const data = await responseCountry.json();
        const countryList = data.map(country => country.name.common).sort();
        setCountries(countryList);

        const storedUser = decryptData(localStorage.getItem('H$S%#PR32S'));
        if (storedUser) {
          setValue('first_name', storedUser.first_name);
          setValue('last_name', storedUser.last_name);
          setValue('email', storedUser.email);
        }
        const response = await axiosInstance.get('/get-shipping-address/');
        if(response.data) {
          setValue('address', response.data.address);
          setValue('city', response.data.city);
          setValue('zip_code', response.data.zip_code);
          setValue('country', response.data.country);
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, [setValue]);

  const onSubmit = data => {
    localStorage.setItem('H$S%#PR32S', encryptData(data));
    dispatch(updateProfile(data)).then(() => {
      toast.success('Profile updated successfully!');
    }).catch((error) => {
      toast.error('Failed to update profile!');
    });
  };

  return (
    <div className="mx-auto mt-4 mb-12 max-w-3xl">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Profile Settings</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-12 gap-8">
          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="first_name">
              First Name
            </label>
            <input
              id="first_name"
              name="first_name"
              type="text"
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.first_name && 'border-red-500'}`}
              {...register('first_name', {
                required: 'First Name is required',
                maxLength: {
                  value: 50,
                  message: 'First Name must be less than 50 characters'
                }
              })}
            />
            {errors.first_name && <p className="text-red-500 text-sm mt-1">{errors.first_name.message}</p>}
          </div>

          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="last_name">
              Last Name
            </label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.last_name && 'border-red-500'}`}
              {...register('last_name', {
                required: 'Last Name is required',
                maxLength: {
                  value: 50,
                  message: 'Last Name must be less than 50 characters'
                }
              })}
            />
            {errors.last_name && <p className="text-red-500 text-sm mt-1">{errors.last_name.message}</p>}
          </div>

          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              disabled
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.email && 'border-red-500'}`}
              {...register('email', {
                required: 'Email is required',
                maxLength: {
                  value: 100,
                  message: 'Email must be less than 100 characters'
                }
              })}
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
          </div>

          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="address">
              Address
            </label>
            <input
              id="address"
              name="address"
              type="text"
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.address && 'border-red-500'}`}
              {...register('address', {
                required: 'Address is required',
                maxLength: {
                  value: 300,
                  message: 'Address must be less than 100 characters'
                }
              })}
            />
            {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address.message}</p>}
          </div>

          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="city">
              City
            </label>
            <input
              id="city"
              name="city"
              type="text"
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.city && 'border-red-500'}`}
              {...register('city', {
                required: 'City is required',
                maxLength: {
                  value: 50,
                  message: 'City must be less than 50 characters'
                }
              })}
            />
            {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city.message}</p>}
          </div>

          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="zip_code">
              Zip Code
            </label>
            <input
              id="zip_code"
              name="zip_code"
              type="text"
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.zip && 'border-red-500'}`}
              {...register('zip_code', {
                required: 'Zip Code is required',
                maxLength: {
                  value: 10,
                  message: 'Zip Code must be less than 10 characters'
                }
              })}
            />
            {errors.zip_code && <p className="text-red-500 text-sm mt-1">{errors.zip_code.message}</p>}
          </div>

          <div className="mb-1 col-span-6">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="country">
              Country
            </label>
            <select
              id="country"
              name="country"
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.country && 'border-red-500'}`}
              {...register('country', {
                required: 'Country is required'
              })}
            >
              <option value="">Select a country</option>
              {countries.map(country => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
            {errors.country && <p className="text-red-500 text-sm mt-1">{errors.country.message}</p>}
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <button type="submit" className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
            Save Changes
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ProfileSettings;
