import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setLogoutFunction } from './axiosInstance';
import { logout as logoutAction } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { encryptData } from '../utils/encryption';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('S%$FO*$#S7'));
  const navigate = useNavigate();  
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('S%$FO*$#S7');
    if (token) {
      setIsAuthenticated(true);
    }
    setLogoutFunction(logout); 
  }, []);

  const login = (res, previous_url) => {
    localStorage.setItem('H$S%#PR32S', encryptData(res.user));
    localStorage.setItem('S%$FO*$#S7', encryptData(res.access));
    localStorage.setItem('G5$K&#S*#B', encryptData(res.refresh));
    setIsAuthenticated(true);
    if(previous_url === '/') {
      navigate('/');
    } else {
      localStorage.removeItem('previous_url');
      navigate('/checkout');
    }
  };

  const logout = () => {
    localStorage.removeItem('H$S%#PR32S');
    localStorage.removeItem('S%$FO*$#S7');
    localStorage.removeItem('G5$K&#S*#B');
    setIsAuthenticated(false);
    dispatch(logoutAction()); 
    navigate('/signin');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
