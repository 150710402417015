import React from 'react';
import { FaCheckCircle, FaSyncAlt, FaComments, FaBullseye, FaLongArrowAltRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const InterviewAiService = () => {
  return (
    <section className="overflow-hidden bg-white py-16">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <img src="images/service-1.jpg" alt="Interview AI Solutions" className="w-full rounded-xl shadow-xl ring-1 ring-gray-300/10" />

          <div className="lg:pl-8">
            <div className="max-w-lg">
              <h2 className="inline-block px-3 py-1 text-sm tracking-wider text-blue-600 font-bold uppercase rounded-full bg-blue-50">Enhance Your Hiring Process</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AI-Powered Interview Solutions</p>
              <p className="mt-6 text-lg leading-8 text-gray-700">
                Leverage the power of AI to streamline and improve your hiring process. Our advanced AI solutions ensure a more efficient, accurate, and engaging interview experience for both recruiters and candidates.
              </p>
              <dl className="mt-10 space-y-8 text-base leading-7 text-gray-600">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <FaSyncAlt className="absolute left-1 top-1 h-5 w-5 text-blue-600" />
                    Automated Interview Scheduling
                  </dt>
                  <dd className="inline"> Easily manage and schedule interviews with intelligent automation, saving you valuable time.</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <FaBullseye className="absolute left-1 top-1 h-5 w-5 text-blue-600" />
                    AI-Driven Candidate Assessments
                  </dt>
                  <dd className="inline"> Conduct thorough and unbiased candidate evaluations using advanced AI algorithms.</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <FaComments className="absolute left-1 top-1 h-5 w-5 text-blue-600" />
                    Real-Time Feedback
                  </dt>
                  <dd className="inline"> Provide immediate and constructive feedback to candidates with AI-generated insights.</dd>
                </div>
              </dl>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
                <Link to="/signup"
                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Get Started Now</Link>
                <Link to="/contact" className="flex rounded-md bg-white-600 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-lg hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Connect with us<span aria-hidden="true"><FaLongArrowAltRight size={21} className='ml-1 text-gray-600' /></span></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InterviewAiService;
