import React from 'react';

const ServiceSection = () => {
  return (
    <section className="overflow-hidden bg-white py-16">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <img src="images/service-1.jpg" alt="Generative AI Solutions" className="w-full rounded-xl shadow-xl ring-1 ring-gray-300/10" />

          <div className="lg:pl-8">
            <div className="max-w-lg">
              <h2 className="text-sm font-semibold leading-7 text-blue-600">Transform Your Business</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Generative AI Solutions</p>
              <p className="mt-6 text-lg leading-8 text-gray-700">
                Unlock the potential of generative AI to enhance creativity, streamline workflows, and drive innovation across your business operations.
              </p>
              <dl className="mt-10 space-y-8 text-base leading-7 text-gray-600">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <svg className="absolute left-1 top-1 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 2a8 8 0 100 16 8 8 0 000-16zm1 11H9v-1h2v1zm0-3H9V5h2v5z" />
                    </svg>
                    Creative Content Generation
                  </dt>
                  <dd className="inline">Generate high-quality content, from text to images, with our state-of-the-art AI models.</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <svg className="absolute left-1 top-1 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clipRule="evenodd" />
                    </svg>
                    Advanced Data Analysis
                  </dt>
                  <dd className="inline">Utilize generative AI to analyze complex datasets and uncover actionable insights.</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <svg className="absolute left-1 top-1 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M14.5 10a4.5 4.5 0 004.284-5.882c-.105-.324-.51-.391-.752-.15L15.34 6.66a.454.454 0 01-.493.11 3.01 3.01 0 01-1.618-1.616.455.455 0 01.11-.494l2.694-2.692c.24-.241.174-.647-.15-.752a4.5 4.5 0 00-5.873 4.575c.055.873-.128 1.808-.8 2.368l-7.23 6.024a2.724 2.724 0 103.837 3.837l6.024-7.23c.56-.672 1.495-.855 2.368-.8.096.007.193.01.291.01zM5 16a1 1 0 11-2 0 1 1 0 012 0z" clipRule="evenodd" />
                    </svg>
                    Personalized Customer Experiences
                  </dt>
                  <dd className="inline">Deliver highly personalized experiences to your customers using AI-driven insights.</dd>
                </div>
              </dl>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#" className="rounded-md bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500">Get Started for Free</a>
              <a href="#" className="text-sm font-semibold leading-6 text-gray-700">Schedule a Demo<span aria-hidden="true"> →</span></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
