import React from "react";
import { FaRegCheckCircle, FaChartLine } from 'react-icons/fa';
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GiAmericanShield } from "react-icons/gi";

const ProjectFaq = ({ faqs }) => { 

    return (
        <section>
            <div className="container mx-auto px-6 lg:px-8">
                <div className="mt-8">
                    <p className="font-semibold text-gray-700 mb-4">Find answers to common questions about our solutions.</p>

                    {faqs.map((faq, index) => (
                        <div key={index} className="bg-white p-4 rounded-lg shadow mb-4">
                            <p className="font-bold text-gray-900">{faq.question}</p>
                            <p className="mt-2 text-gray-700">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ProjectFaq