import React from 'react';

const TestModel = () => {
  // Custom styles for iframe
  const customStyles = {
    iframeContainer: {
        border: 'none',
        display: 'block',
        marginBottom: 10,
        zIndex: 10,
      height: '1400px', // default for desktop
    },
    iframeMobile: {
      height: '2400px', // for mobile devices
    },
    customeIframeWrapper: {
        height: '1400px', // default for desktop
    }, 
  };

  return (
    <>
      <div className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-0 md:px-8">
          <div className="py-12 md:py-20">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold mb-8 text-gray-500">Test Model</h2>
              {/* <p className="mt-4 text-lg text-gray-500">
                Test the model with your own data and see how it performs.
              </p> */}
            </div>

            {/* Custom iframe container with responsive height */}
            <div
              className="relative custome-iframe w-full rounded-lg z-10"
              style={{
                ...customStyles.customeIframeWrapper,
                // Apply mobile-specific styles using media queries
                ...(window.innerWidth <= 768 ? customStyles.iframeMobile : {}),
              }}
            >
              <iframe
                src="https://chrisjohnson111-test333.hf.space"
                title="Test Model"
                className="absolute iframe-container testwork custom-scrollbar top-0 left-0 w-full rounded-lg border-none"
                style={{
                  ...customStyles.iframeContainer,
                  // Apply mobile-specific styles using media queries
                  ...(window.innerWidth <= 768 ? customStyles.iframeMobile : {}),
                }}
                allowFullScreen
              ></iframe>
            </div>

            {/* Additional content below the iframe */}
            <div className="absolute w-full lg:w-[50em] -mt-24 z-40 bg-white h-[10em] bottom-[30]"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestModel;
