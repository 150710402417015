import React from "react";
import { FaChartLine } from 'react-icons/fa';
import { FaReact, FaMobileAlt} from 'react-icons/fa';
import { GoDatabase } from "react-icons/go";
import { MdLockOutline } from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import { BiExport } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";

const ProjectTechnical = ({ technologies }) => {

    const iconMap = {
        FaChartLine: <FaChartLine className="text-blue-600 w-6 h-6 mx-auto" />,
        FaReact: <FaReact className="text-blue-600 w-7 h-7 mx-auto" />,
        FaMobileAlt: <FaMobileAlt className="text-blue-600 w-6 h-6 mx-auto" />,
        GoDatabase: <GoDatabase className="text-blue-600 w-7 h-7 mx-auto" />,
        MdLockOutline: <MdLockOutline className="text-blue-600 w-8 h-8 mx-auto" />,
        TbFileInvoice: <TbFileInvoice className="text-blue-600 w-8 h-8 mx-auto" />,
        BiExport: <BiExport className="text-blue-600 w-8 h-8 mx-auto" />,
        IoSettingsOutline: <IoSettingsOutline className="text-blue-600 w-7 h-7 mx-auto" />
    };

    const renderIcon = (iconName) => {
        return iconMap[iconName] || null;
    };

    return (
        <section>
            <div className="container mx-auto px-6 lg:px-8">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-semibold text-gray-900 mb-6">Technical Specifications</h2>
                    <p className="text-base text-gray-700">Understand the technical aspects of our solution.</p>
                </div>
                <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
                    {technologies && technologies.map((tech, index) => (
                    <div key={index} className="bg-white p-6 rounded-lg shadow-lg transform transition hover:scale-105">
                        {renderIcon(tech.icon)}
                        <h3 className="mt-4 text-xl font-semibold text-gray-900 text-center">{tech.name}</h3>
                        <p className="mt-2 text-gray-700 text-center">{tech.description}</p>
                    </div>
                    ))} 
                </div>
            </div>
        </section>
    )
}

export default ProjectTechnical