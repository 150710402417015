import React, { useState } from 'react';
import { FaCloudDownloadAlt, FaUser, FaCog } from 'react-icons/fa';
import { MdSupportAgent } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import ProfileSettings from './ProfileSettings';
import Download from './Download';
import ChatPage from '../Support/ChatPage';

const TabView = () => {
    const [activeTab, setActiveTab] = useState("dashboard");
    const location = useLocation();
    const currentPath = location.pathname.split('/').pop();

    return (
        <div className="mx-4 my-12 md:mx-auto lg:w-4/5">
            <div className="relative right-0">
                <ul className="relative flex flex-wrap list-none" data-tabs="tabs" role="list">
                    <li className={`flex-auto text-center border-b-2   ${currentPath === 'download' ? 'border-blue-700' :'border-gray-400'}`}>
                        <Link
                            to="/download"
                            className={`flex items-center justify-center w-full px-4 py-2 transition-all ease-in-out border-0 cursor-pointer font-semibold hover:text-blue-700 ${currentPath === 'download' ? 'w-1xl text-blue-700' : 'text-slate-700'}`}
                            role="tab"
                            aria-selected={currentPath === 'download'}
                        >
                            <FaCloudDownloadAlt className="w-5 h-5 mr-1" />
                            <span>Download</span>
                        </Link>
                    </li>
                    <li className={`flex-auto text-center border-b-2 ${currentPath === 'support' ? 'border-blue-700' :'border-gray-400'}`}>
                        <Link
                            to="/support"
                            className={`flex items-center justify-center w-full px-4 py-2 transition-all hover:text-blue-700 ease-in-out border-0 cursor-pointer font-semibold ${currentPath === 'support' ? 'text-blue-700' : 'text-slate-700'}`}
                            role="tab"
                            aria-selected={currentPath === 'support'}
                        >
                            <MdSupportAgent className="w-7 h-7 mr-1" />
                            <span>Support</span>
                        </Link>
                    </li> 
                    <li className={`flex-auto text-center border-b-2 ${currentPath === 'settings' ? 'border-blue-700' :'border-gray-400'}`}>
                        <Link
                            to="/settings"
                            className={`flex items-center justify-center w-full px-4 py-2 transition-all hover:text-blue-700 ease-in-out border-0 cursor-pointer font-semibold ${currentPath === 'settings' ? 'text-blue-700' : 'text-slate-700'}`}
                            role="tab"
                            aria-selected={currentPath === 'settings'}
                        >
                            <FaCog className="w-5 h-5 mr-1" />
                            <span>Settings</span>
                        </Link>
                    </li>
                </ul>
                <div className="mt-8 p-6 bg-white rounded-lg shadow-md">
                    {currentPath === "download" && (
                        <div>
                            <Download />
                        </div>
                    )} 
                    {currentPath === "support" && (
                        <div>
                             <ChatPage />
                        </div>
                    )}
                    {currentPath === "settings" && (
                        <div>
                             <ProfileSettings />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TabView;