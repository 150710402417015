import React, { useEffect, useState } from 'react';
import ProjectPage from './Project/ProjectPage';
import ProjectDetail from './Project/ProjectDetails';
import { getProjectDetails } from '../../redux/actions/projectActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const Project = () => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [projectCodeList, setProjectCodeList] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getProjectDetails(id)).then((data) => {
      if(data.project) {
        setProjectDetails(data.project); 
        setProjectCodeList(data.project_code);
      } 
    });
  }, []);

  return (
    <>
    {projectDetails && projectCodeList && projectCodeList.length > 0 && (<>
      <ProjectPage projectDetails={projectDetails} projectCodeList={projectCodeList} />
      <ProjectDetail projectDetails={projectDetails} />
    </>)}
    </>
  );
};

export default Project;
