import React from 'react';

const testimonials = [
  {
    name: "Daniella Rivera",
    role: "Mobile Developer at TechCorp",
    image: "https://randomuser.me/api/portraits/women/12.jpg",
    text: "GenAI Box has revolutionized our mobile development process. The AI-driven features save us countless hours each week and the accuracy is unmatched."
  },
  {
    name: "Jane Smith",
    role: "Marketing Specialist at Creative Solutions",
    image: "https://randomuser.me/api/portraits/women/14.jpg",
    text: "Integrating GenAI Box with our existing systems was seamless. The support team was incredibly helpful and always available to assist us."
  },
  {
    name: "Yanick Johnson",
    role: "Software Developer at CodeWorks",
    image: "https://randomuser.me/api/portraits/women/18.jpg",
    text: "The data extraction capabilities of GenAI Box are remarkable. It has drastically reduced errors and improved our overall workflow efficiency."
  },
  {
    name: "Jane Doe",
    role: "Project Manager at InnovateX",
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    text: "GenAI Box's data visualization tools provide clear insights, helping us make informed decisions quickly and effectively."
  },
  {
    name: "Andy Brown",
    role: "Operations Manager at LogisticsPro",
    image: "https://randomuser.me/api/portraits/men/62.jpg",
    text: "The reliability and uptime of GenAI Box's services are exceptional. We can trust that our operations will run smoothly at all times."
  },
  {
    name: "Yanndy White",
    role: "Financial Analyst at MoneyMatters",
    image: "https://randomuser.me/api/portraits/men/19.jpg",
    text: "Using GenAI Box has streamlined our financial processes and improved accuracy. It's been a great decision for our team."
  },
];

const TestimonialsSection = () => {
  return (
    <section className="py-20 bg-gradient from-white via-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-300" id="reviews">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="mb-10 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-gray-900 dark:text-white md:text-4xl">
            Our Clients Love Us
          </h2>
        </div>
        <div className="md:columns-2 lg:columns-3 gap-8 space-y-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="aspect-auto p-8 border border-gray-200 dark:border-gray-700 rounded-3xl bg-white dark:bg-gray-800 shadow-lg"
            >
              <div className="flex gap-4">
                <img
                  className="w-12 h-12 rounded-full"
                  src={testimonial.image}
                  alt={`${testimonial.name} avatar`}
                  width="400"
                  height="400"
                  loading="lazy"
                />
                <div>
                  <h6 className="text-lg font-medium text-gray-900 dark:text-gray-100">{testimonial.name}</h6>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{testimonial.role}</p>
                </div>
              </div>
              <p className="mt-8 text-gray-700 dark:text-gray-300">{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
