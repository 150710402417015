import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Userlogin, googleAuth, resendVerifyEmail } from '../../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { useAuth } from '../../../Common/AuthProvider';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SigninPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { loading, error } = authState;
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);
        }
    }, [isAuthenticated]);

    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        } else if (email.length > 100) {
            errors.email = 'Email must be less than 100 characters.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
        } else if (password.length > 100) {
            errors.password = 'Password must be less than 100 characters.';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const userData = {
                email,
                password,
            };
            setIsSubmitting(true);
            try {
                const res = await dispatch(Userlogin(userData));
                setIsSubmitting(false);
                if (res && res.access) {
                    if (!res.user.is_verified) {
                        navigate('/email-verification');
                    } else {
                        const previous_url = localStorage.getItem('previous_url') || '/';
                        login(res, previous_url);
                    }
                }
            } catch (error) {
                setIsSubmitting(false);
                console.error("Login error:", error);
            }
        }
    };

    const handleResendVerification = () => {
        dispatch(resendVerifyEmail(email)).then((res) => {
            if (res) {  
                navigate('/email-verification', { state: { email: email } });
            }
        });
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const userData = {
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            id_token: token,
        };

        await dispatch(googleAuth(userData)).then((res) => {
            if (res && res.access) {
                const previous_url = localStorage.getItem('previous_url') || '/';
                login(res, previous_url);
            }
        });
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const renderError = (err) => (
        <div>
          {err}
          {err.includes('Email is not verified') && (
            <button onClick={handleResendVerification} className="ml-2 underline">
              Resend Verification Email
            </button>
          )}
        </div>
    );

    return (
        <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
            <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
                <div className="max-w-screen-xl m-0 sm:m-10 flex justify-center flex-1">
                    <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-white shadow-lg rounded-lg">
                        <div className="mt-12 flex flex-col items-center">
                            <h1 className="text-3xl font-extrabold text-gray-900">
                                Welcome Back!
                            </h1>
                            <p className="text-gray-600 mt-2">
                                Please sign in to your account
                            </p>
                            <div className="w-full flex-1 mt-8">
                                <div className="flex flex-col items-center">
                                    <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        onFailure={handleGoogleFailure}
                                    />
                                </div>

                                <div className="my-12 border-b text-center">
                                    <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                                        Or sign in with email
                                    </div>
                                </div>

                                <div className="mx-auto max-w-xs">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <input
                                                className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.email ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                maxLength={100}
                                            />
                                            {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                                        </div>
                                        <div className="mb-4 relative">
                                            <input
                                                className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                maxLength={100}
                                            />
                                            <span
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                                            >
                                                {showPassword ? <FaEyeSlash className='text-gray-500' /> : <FaEye className='text-gray-500' />}
                                            </span>
                                            {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                                        </div>
                                        <button
                                            type="submit"
                                            className={`mt-5 tracking-wide font-semibold text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600'}`}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? (
                                                <span>Submitting...</span>
                                            ) : (
                                                <>
                                                    <svg
                                                        className="w-6 h-6 -ml-2"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                                        <circle cx="8.5" cy="7" r="4" />
                                                        <path d="M20 8v6M23 11h-6" />
                                                    </svg>
                                                    <span className="ml-3">Sign In</span>
                                                </>
                                            )}
                                        </button>
                                    </form>
                                    {loading && <p className="mt-4 text-gray-600">Loading...</p>}
                                    {error && (
                                        <div className="text-red-500 mt-2 text-sm">
                                        {Array.isArray(error) ? error.map((err, index) => (
                                            <div key={index}>
                                            {renderError(err)}
                                            </div>
                                        )) : renderError(error)}
                                        </div>
                                    )}
                                </div>
                                <div className="mt-6 text-center">
                                    <Link to="/reset-password" className="text-sm font-medium text-blue-500 hover:text-blue-700">
                                        Forgot your password?
                                    </Link>
                                </div>
                                <div className="mt-2 text-center">
                                    <span className="text-sm font-medium text-gray-600">
                                        Don't have an account?
                                    </span>
                                    <Link to="/signup" className="ml-2 text-sm font-medium text-blue-500 hover:text-blue-700">
                                        Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 bg-blue-100 text-center hidden lg:flex">
                        <div
                            className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
                            style={{ backgroundImage: 'url("./images/signin-1.svg")' }}
                        ></div>
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default SigninPage;
