import React from 'react';

const ServiceDetailSection = () => {
  return (
    <section className="p-10 py-20">
      <div className="container mx-auto px-6 lg:px-8 text-gray-700">
        <div className="grid lg:grid-cols-12 gap-16 items-center">
          <div className="lg:col-span-5">
            <h2 className="text-6xl font-semibold mb-8 leading-tight">
            Our Expertise in AI-Driven Interviews
            </h2>
            <p className="text-xl leading-relaxed mb-8">
            Harness the power of AI to transform your interview process. Our team of specialists ensures each solution is tailored to meet your unique needs, delivering unparalleled efficiency and accuracy.
            </p>
          </div>
          <div className="lg:col-span-7">
            <div className=" text-gray-900">
              <div className="grid gap-10 md:grid-cols-2">
                <div>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>01</span> <p className='my-6'> Intelligent Candidate Matching</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                  Using advanced algorithms, we match candidates' skills and experiences with job requirements, ensuring a perfect fit for every role. 
                  </p>
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>02</span> <p className='my-6'> Enhanced Interview Analytics</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                    We design intuitive and engaging user interfaces that enhance the user experience and drive engagement.
                  </p>
                </div>
                <div className='md:mt-6'>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>03</span> <p className='my-6'> Seamless Code Integration</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                  Our solutions seamlessly integrate with your existing HR systems, making the adoption of AI-driven interviews effortless and efficient.
                  </p>
                </div>
                <div className='md:mt-6'>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>04</span> <p className='my-6'> Continuous Improvement</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                  We continually optimize our AI models to adapt to changing hiring trends and provide the best possible interview experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}; 

export default ServiceDetailSection;
