import axiosInstance from '../../Common/axiosInstance';
import {
    ADD_ORDER_REQUEST,
    ADD_ORDER_SUCCESS,
    ADD_ORDER_FAIL,

    ADD_PAYMENT_REQUEST,
    ADD_PAYMENT_SUCCESS,
    ADD_PAYMENT_FAIL,

    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAIL,

} from '../constants/orderConstants';

import { API_URL } from '../env';

import { startLoading, stopLoading } from './loadingActions';
import { toast } from 'react-toastify';

export const addOrderAndPayment = (data) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: ADD_ORDER_REQUEST }); 
        
        const response = await axiosInstance.post('/create-order/', data);

        dispatch({
            type: ADD_ORDER_SUCCESS,
            payload: response.data,
        });
        return response.data;

    } catch (error) {
        dispatch({
            type: ADD_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    } finally {
        dispatch(stopLoading());
    }
}

export const addPayment = (paymentInfo) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: ADD_PAYMENT_REQUEST }); 

        const response = await axiosInstance.post('/create_payment/', paymentInfo);

        dispatch({
            type: ADD_PAYMENT_SUCCESS,
            payload: response.data,
        });

        return response.data;

    } catch (error) {
        dispatch({
            type: ADD_PAYMENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    } finally {
        dispatch(stopLoading());
    }
}

export const getOrder = (id) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: GET_ORDER_REQUEST });

        const { data } = await axiosInstance.get(`/order/${id}/`);

        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: data,
        });

        return data;

    } catch (error) {
        dispatch({
            type: GET_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    } finally {
        dispatch(stopLoading());
    }
}