export const INVOICE_TYPE_LOADING = 'INVOICE_TYPE_LOADING'
export const INVOICE_TYPE_SUCCESS = 'INVOICE_TYPE_SUCCESS'
export const INVOICE_TYPE_FAIL = 'INVOICE_TYPE_FAIL'

export const UPLOAD_INVOICE_TYPE_LOADING = 'UPLOAD_INVOICE_TYPE_LOADING'
export const UPLOAD_INVOICE_TYPE_SUCCESS = 'UPLOAD_INVOICE_TYPE_SUCCESS'
export const UPLOAD_INVOICE_TYPE_FAIL = 'UPLOAD_INVOICE_TYPE_FAIL'

export const INVOICE_TYPE_LIST_LOADING = 'INVOICE_TYPE_LIST_LOADING'
export const INVOICE_TYPE_LIST_SUCCESS = 'INVOICE_TYPE_LIST_SUCCESS'
export const INVOICE_TYPE_LIST_FAIL = 'INVOICE_TYPE_LIST_FAIL'

export const UPLOAD_INVOICE_LOADING = 'UPLOAD_INVOICE_LOADING'
export const UPLOAD_INVOICE_SUCCESS = 'UPLOAD_INVOICE_SUCCESS'
export const UPLOAD_INVOICE_FAIL = 'UPLOAD_INVOICE_FAIL'

export const INVOICE_LIST_LOADING = 'INVOICE_LIST_LOADING'
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS'
export const INVOICE_LIST_FAIL = 'UPLOAD_INVOICE_FAIL'