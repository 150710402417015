import React, { useState } from 'react';

const faqs = [
  {
    question: "What is GenAI Box?",
    answer: "GenAI Box is a platform offering generative AI-based projects with standard quality code and high security. It's designed to help businesses leverage AI technology seamlessly."
  },
  {
    question: "How can GenAI Box improve my business?",
    answer: "Our generative AI solutions automate and enhance various business processes, from data extraction to customer interaction, increasing efficiency and reducing costs."
  },
  {
    question: "What makes GenAI Box projects secure?",
    answer: "We implement advanced security measures, including encryption and compliance with global data protection standards, to ensure your data is safe and secure."
  },
  {
    question: "Can I customize the AI projects from GenAI Box?",
    answer: "Yes, our projects are designed with flexibility in mind. You can customize them to fit your specific business needs and integrate them seamlessly into your existing systems."
  },
  {
    question: "What support options are available with GenAI Box?",
    answer: "We offer comprehensive support, including detailed documentation, live chat, email support, and a robust community forum to assist you with any queries or issues."
  }
];

const ContactFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-gray-50 py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <button
                onClick={() => toggleFAQ(index)}
                className="flex justify-between items-center w-full text-left text-gray-900 font-medium focus:outline-none"
              >
                <span>{faq.question}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 ${
                    activeIndex === index ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeIndex === index && (
                <div className="mt-4 text-gray-700">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactFAQ;
