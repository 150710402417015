import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from './Modal';
import { subscribe } from '../../../redux/actions/authActions';

const CallToActionSection = () => {
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState('');
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = () => {
    if (email.length > 100) {
      setErrors('Email must be less than 100 characters.');
      return;
    }

    if (!validateEmail(email)) {
      setErrors('Please enter a valid email address.');
      return;
    }

    dispatch(subscribe({ email }))
      .then(() => {
        setEmail('');
        setErrors('');
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.error('Subscription failed:', error);
      });
  };

  return (
    <div className="relative py-16 dark:bg-gray-900">
      <div aria-hidden="true" className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
        <div className="blur-[106px] h-56 bg-gradient-to-br from-teal-500 to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>

      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="relative">
          <div className="flex items-center justify-center -space-x-2">
            <img loading="lazy" width="400" height="400" src="https://randomuser.me/api/portraits/women/12.jpg" alt="member photo" className="h-8 w-8 rounded-full object-cover" />
            <img loading="lazy" width="200" height="200" src="https://randomuser.me/api/portraits/women/45.jpg" alt="member photo" className="h-12 w-12 rounded-full object-cover" />
            <img loading="lazy" width="200" height="200" src="https://randomuser.me/api/portraits/women/60.jpg" alt="member photo" className="z-10 h-16 w-16 rounded-full object-cover" />
            <img loading="lazy" width="200" height="200" src="https://randomuser.me/api/portraits/women/4.jpg" alt="member photo" className="relative h-12 w-12 rounded-full object-cover" />
            <img loading="lazy" width="200" height="200" src="https://randomuser.me/api/portraits/women/34.jpg" alt="member photo" className="h-8 w-8 rounded-full object-cover" />
          </div>

          <div className="mt-6 m-auto space-y-6 md:w-8/12 lg:w-7/12">
            <h1 className="text-center text-4xl font-bold text-gray-800 dark:text-white md:text-5xl">Stay Updated with GenAI Box</h1>
            <p className="text-center text-xl text-gray-600 dark:text-gray-300">
              Subscribe to get the latest updates on our AI projects and innovations delivered straight to your inbox.
            </p>
            <div className="flex flex-wrap justify-center gap-6">
              <input
                type="email"
                value={email}
                maxLength={100}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full max-w-xs px-4 py-2 text-base text-gray-900 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                onClick={handleSubscribe}
                className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
              >
                Subscribe
              </button>
            </div>
            {errors && <p className="text-red-500 text-center text-sm mt-2">{errors}</p>}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold text-gray-800">Thank You for Subscribing!</h2>
        <p className="mt-4 text-gray-600">We will keep you updated with the latest projects and updates from GenAI Box.</p>
      </Modal>
    </div>
  );
};

export default CallToActionSection;
