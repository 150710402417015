import React, { useEffect, useState } from 'react';
import Sidebar from './Document/Sidebar';
import MainContent from './Document/MainContent';
import { useDispatch } from 'react-redux';
import { getprojectDcument } from '../../redux/actions/projectActions';
import { useLocation } from 'react-router-dom';

const Document = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('introduction'); // Default to the first section
    const [contents, setContents] = useState([]);
    const [sideBar, setSideBar] = useState([]);
    const location = useLocation();
    const { content_id } = location.state || { content_id: null }; // Providing a default value
    
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (content_id) {
            dispatch(getprojectDcument(content_id))
              .then((data) => {
                console.log('data:', data);
                if (data && data.length > 0) {
                    setContents(data)
                    setSideBar({
                        title: data.title,
                        content: data.content
                    });
                }
              })
              .catch((error) => {
                console.error("Error fetching project document:", error);
              });
        } else {
            console.error("No content_id available in location.state");
        }
    }, [dispatch, content_id]);
    

    return (
        <div className="md:flex border-y">
            <div className="md:flex ">
                <Sidebar
                    sidebarOpen={sidebarOpen}
                    toggleSidebar={toggleSidebar}
                    contents={contents}
                    activeSection={activeSection} // Pass the activeSection state to Sidebar
                />
                <div className="md:flex-1 md:ml-64 border-l">
                    <MainContent
                        toggleSidebar={toggleSidebar}
                        contents={contents}
                        setActiveSection={setActiveSection} // Pass the setActiveSection function to MainContent
                    />
                </div>
            </div>
        </div>
    );
};

export default Document;
