import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../../../redux/env';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const CreateNewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/signin');
    } 
  }, [token, navigate]);

  const authState = useSelector((state) => state.auth);
  const { loading, error, message } = authState;

  const validate = () => {
    const errors = {};
    if (!password) {
      errors.password = 'Password is required.';
    } else if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters.';
    } else if (password.length > 50) {
      errors.password = 'Password must be less than 50 characters.';
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required.';
    } else if (confirmPassword.length < 6) {
      errors.confirmPassword = 'Confirm Password must be at least 6 characters.';
    } else if (confirmPassword.length > 50) {
      errors.confirmPassword = 'Confirm Password must be less than 50 characters.';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      if (token && password) {
        axios.post(`${API_URL}/auth/password-reset/`, { token, password })
          .then(response => {
            setIsSubmitting(false);
            toast.success('Password reset successfully. Please login with your new password.');
            navigate('/signin');
          })
          .catch(error => {
            setIsSubmitting(false);
            toast.error('Password reset failed. Please try again.');
          });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
      <div className="max-w-screen-xl m-0 sm:m-10 flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-white shadow-lg rounded-lg">
          <div className="mt-4 flex flex-col items-center">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Create New Password
            </h1>
            <p className="text-gray-600 mt-2">
              Enter your new password below.
            </p>  
            <div className="w-full flex-1 mt-8">
              <div className="mx-auto max-w-xs">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 relative">
                    <input
                      className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      maxLength={50}
                    />
                    {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                  </div>
                  <div className="mb-4 relative">
                    <input
                      className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      maxLength={50}
                    />
                    {errors.confirmPassword && <p className="text-red-500 text-xs mt-2">{errors.confirmPassword}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={(e) => setShowPassword(e.target.checked)}
                        className="form-checkbox"
                      />
                      <span className="ml-2 text-sm text-gray-600">Show Password</span>
                    </label>
                  </div>
                  <button
                    type="submit"
                    className={`mt-5 tracking-wide font-semibold text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600'}`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <>
                        <svg
                          className="w-6 h-6 -ml-2"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                          <circle cx="8.5" cy="7" r="4" />
                          <path d="M20 8v6M23 11h-6" />
                        </svg>
                        <span className="ml-3">Reset Password</span>
                      </>
                    )}
                  </button>
                </form>
                {loading && <p className="mt-4">Loading...</p>}
                {error && <p className="text-red-500 mt-4">{error}</p>}
                {message && <p className="text-green-500 mt-4">{message}</p>}
                <div className="mt-6 text-center">
                  <Link to="/signin" className="text-sm font-medium text-blue-500 hover:text-blue-700">
                    Back to Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateNewPassword;
