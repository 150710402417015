// src/reducers/authReducers.js
import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAIL,

    USER_ORDERS_REQUEST,
    USER_ORDERS_SUCCESS,
    USER_ORDERS_FAIL,
} from '../constants/authConstants';

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case REGISTER_REQUEST:
        case LOGIN_REQUEST:
        case VERIFY_EMAIL_REQUEST:
            return { loading: true };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
        case VERIFY_EMAIL_SUCCESS:
            return { loading: false, userInfo: action.payload };

        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case VERIFY_EMAIL_FAIL:
            return { loading: false, error: action.payload };

        case LOGOUT:
            return {};

        default:
            return state;
    }
};

export const userOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case USER_ORDERS_REQUEST:
            return { loading: true, orders: [] };
        case USER_ORDERS_SUCCESS:
            return { loading: false, orders: action.payload };
        case USER_ORDERS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};