import React, { useState } from 'react';
import { FiSend, FiImage } from 'react-icons/fi';

const ChatInput = ({ project, onSendMessage }) => {
    const [text, setText] = useState('');
    const [image, setImage] = useState(null);
    const [imagePrev, setImagePrev] = useState(null);
    const [error, setError] = useState('');

    const handleSend = () => {
        if (!text && !image) {
            setError('Please enter a message or upload an image.');
            return;
        }

        if (text.length > 1000) {
            setError('Message cannot exceed 1000 characters.');
            return;
        }

        const newMessage = {
            user_question: text,
            user_que_image: image,
            project,
            fromUser: true,
        };
        onSendMessage(newMessage);
        setText('');
        setImage(null);
        setImagePrev(null);
        setError('');
        document.getElementById('image-upload').value = null;
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/')) {
                setError('Please upload a valid image file.');
                return;
            }

            if (file.size > 2 * 1024 * 1024) { // 2 MB
                setError('Image size must be less than 2 MB.');
                return;
            }

            setImage(file);
            setImagePrev(URL.createObjectURL(file));
            setError('');
        }
    };

    return (
        <div className="p-4 border-t border-gray-300 flex flex-col bg-gray-100">
            <div className="flex items-center">
                <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Type your message..."
                    className="flex-grow p-2 border border-gray-300 rounded-lg mr-2"
                    maxLength="1000"
                />
                <label className="cursor-pointer">
                    <FiImage size={24} className="text-gray-500 mr-4 hover:text-gray-700" />
                    <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                    />
                </label>
                <div>
                    {imagePrev && (
                        <img
                            src={imagePrev}
                            alt="Preview"
                            className="h-10 w-10 border-2 border-blue-400 object-cover rounded-full mr-4"
                        />
                    )}
                    <button
                        onClick={handleSend}
                        className="hidden md:block bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 focus:outline-none"
                    >
                        <FiSend size={24} />
                    </button>
                </div>
            </div>
            <button
                onClick={handleSend}
                className="md:hidden flex justify-center items-center mt-4 bg-white text-blue-600 border border-blue-600 p-2 rounded-lg hover:bg-blue-700 focus:outline-none"
            >
                Send <FiSend size={24} className="ml-2" />
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    );
};

export default ChatInput;
