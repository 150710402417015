import { 
    INVOICE_TYPE_LOADING,
    INVOICE_TYPE_SUCCESS,
    INVOICE_TYPE_FAIL,

    UPLOAD_INVOICE_TYPE_LOADING,
    UPLOAD_INVOICE_TYPE_SUCCESS,
    UPLOAD_INVOICE_TYPE_FAIL,

    INVOICE_TYPE_LIST_LOADING,
    INVOICE_TYPE_LIST_SUCCESS,
    INVOICE_TYPE_LIST_FAIL,
} from '../constants/invoiceConstatns' 

export const getInvoiceTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_TYPE_LOADING:
            return { loading: true };

        case INVOICE_TYPE_SUCCESS:
            return { loading: false, invoiceType: action.payload };

        case INVOICE_TYPE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}

export const uploadInvoiceTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_INVOICE_TYPE_LOADING:
            return { loading: true };

        case UPLOAD_INVOICE_TYPE_SUCCESS:
            return { loading: false, uploadedInvoiceType: action.payload };

        case UPLOAD_INVOICE_TYPE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const getInvoiceTypeListReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_TYPE_LIST_LOADING:
            return { loading: true };

        case INVOICE_TYPE_LIST_SUCCESS:
            return { loading: false, invoiceTypeList: action.payload };

        case INVOICE_TYPE_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};