import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBlogs } from '../../../redux/actions/projectActions';
import { FILE_URL } from '../../../redux/env';
import CallToActionSection from '../Home/Calltoactionsection';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);

    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getBlogs()).then((data) => {
        setBlogs(data);
      });
    }, [dispatch]);

    return (
        <div className="bg-gray-50">
            <div className="container mx-auto p-4 pt-12">
                <div className="grid grid-cols-1 pb-12 lg:grid-cols-3 gap-8">
                    
                    {/* Main Blog List */}
                    <div className="lg:col-span-2">
                        <div className="space-y-8">
                            {blogs && blogs.map(blog => (
                                <div key={blog.id} className="md:flex bg-white shadow-lg rounded-lg overflow-hidden">
                                    {blog.image && (
                                        <div className="flex-shrink-0">
                                            <img
                                                src={FILE_URL + blog.image}
                                                alt={blog.title}
                                                className="md:w-60 h-full object-cover"
                                            />
                                        </div>
                                    )}
                                    <div className="p-4 flex flex-col justify-between">
                                        <div>
                                            <Link to={`/blog/${blog.slug}`} className="text-2xl font-bold text-gray-900 hover:text-blue-700">{blog.title}</Link>
                                            <div className="flex items-center space-x-4 text-gray-500 my-4">
                                                <img
                                                    src={FILE_URL + blog.author_image}
                                                    alt={blog.author_name}
                                                    className="w-10 h-10 rounded-full"
                                                />
                                                <span className="text-sm font-medium">{blog.author_name}</span>
                                                <span className="text-sm">
                                                    {new Date(blog.created_at).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                    })}
                                                </span>
                                            </div>
                                            <p className="text-gray-700 mb-4">{blog.excerpt}</p>
                                        </div>
                                        <div className='flex'>
                                        <Link to={`/blog/${blog.slug}`} className=" inline-block mt-3 bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 py-2 rounded-full text-sm font-semibold hover:from-blue-700 hover:to-blue-800">
                                            Read More
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Sidebar */}
                    <div className="space-y-8">
                        
                        {/* About Me */}
                        <div className="bg-white shadow-lg rounded-lg p-6">
                            <h2 className="text-xl font-bold mb-4">About Me</h2>
                            <div className="flex items-center space-x-4 mb-4">
                                <img src="/images/Adrew_Moris.jpg" alt="Profile" className="w-16 h-16 rounded-full" />
                                <div>
                                    <h3 className="text-lg font-semibold">Adrew Moris</h3>
                                    <p className="text-gray-500 text-sm">Software Developer & Blogger</p>
                                </div>
                            </div>
                            <p className="text-gray-700 mb-4">Hi! I'm Adrew Moris, a Software developer with a passion for writing and sharing knowledge.</p>
                            <p className="text-gray-500 text-sm">Follow me on:</p>
                            <div className="flex space-x-4 mt-2">
                                <a href="https://x.com/adrew_it" className="text-blue-600 font-semibold">Twitter</a>
                            </div>
                        </div>

                        {/* Popular Articles */}
                        <div className="bg-white shadow-lg rounded-lg p-6">
                            <h2 className="text-xl font-bold mb-4">Popular Articles</h2>
                            <ul className="space-y-4">
                                {blogs && blogs.slice(0, 3).map(blog => (
                                    <li key={blog.id} className="flex items-start">
                                        <img src={FILE_URL + blog.image} alt={blog.title} className="w-16 h-16 object-cover rounded-lg mr-4" />
                                        <div>
                                            <Link to={`/blog/${blog.slug}`} className="text-md font-semibold text-blue-600 hover:underline">{blog.title}</Link>
                                            <p className="text-gray-500 mt-2 text-sm">
                                                {new Date(blog.created_at).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div> 

                <CallToActionSection />
            </div>
        </div>
    );
};

export default Blogs;
