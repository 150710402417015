import React from 'react';
import ServiceHero from './Service/ServiceHero';
import ServiceSection from './Service/ServiceSection';
import TestimonialsSection from './Home/Testimonialsection';
import ServiceTeamSection from './Service/ServiceTeamSection';
import Divider from './InvoAI/Divider';

const Services = () => {
  return (
    <>
      <ServiceHero />
      <ServiceSection />
      <Divider />
      <ServiceTeamSection /> 
      <TestimonialsSection /> 
    </>
  );
};

export default Services;
