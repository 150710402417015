import React, { useState } from 'react';

const useCases = [
  {
    title: 'Invoice Extraction',
    shortDescription: 'Automate and streamline your invoice processing with high accuracy.',
    fullDescription: 'Our AI-driven invoice extraction tool significantly reduces manual data entry errors and accelerates processing times, ensuring timely and accurate financial operations. This solution helps businesses save time and reduce operational costs, allowing your team to focus on more strategic tasks.'
  },
  {
    title: 'Resume Parsing',
    shortDescription: 'Efficiently parse and analyze resumes to find the best candidates.',
    fullDescription: 'Leverage AI to quickly sift through resumes, extracting key information to match candidates with job requirements more effectively. This solution enhances the recruitment process by speeding up candidate screening and ensuring you find the most qualified candidates faster.'
  },
  {
    title: 'Fashion Classification',
    shortDescription: 'Classify and manage fashion items using advanced AI algorithms.',
    fullDescription: 'AI-powered fashion classification helps streamline inventory management, enabling better product recommendations and categorization. This solution is ideal for fashion retailers looking to improve their e-commerce platforms and enhance customer experience with personalized shopping.'
  },
  {
    title: 'Chatbot Assistance',
    shortDescription: 'Enhance customer support with intelligent AI-powered chatbots.',
    fullDescription: 'Our AI chatbots provide 24/7 customer support, handling queries efficiently and improving user satisfaction. This solution reduces the workload on human agents and ensures that customers receive timely assistance, boosting overall customer engagement and loyalty.'
  },
];

const HomeUseCases = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <section className="py-16 md:pt-32 bg-gradient-to-t from-white via-gray-50 to-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Transformative GenAI Use Cases
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Discover how our generative AI solutions are revolutionizing various industries.
        </p>
        <div className="mt-10">
          <div className="flex justify-center mb-8 overflow-x-auto scrollbar-hide space-x-4">
            {useCases.map((useCase, index) => (
              <button
                key={index}
                className={`px-4 py-2 mx-2 text-sm font-semibold whitespace-nowrap ${activeTab === index ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
                onClick={() => setActiveTab(index)}
              >
                {useCase.title}
              </button>
            ))}
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-left md:text-center">
            <h3 className="text-2xl font-bold text-gray-900">{useCases[activeTab].title}</h3>
            <p className="mt-4 text-lg text-gray-700">{useCases[activeTab].shortDescription}</p>
            <p className="mt-4 text-gray-600">{useCases[activeTab].fullDescription}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeUseCases;
