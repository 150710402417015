import React from 'react';

const NFPrivacyPolicy = () => {
    return (
        <section className="bg-gray-50 dark:bg-gray-900 py-4 md:py-16">
            <div className="container px-4 md:px-8 mx-auto">
                <div className="px-8 py-10 mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-900">
                    <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
                    <p>
                        This privacy policy applies to the Natural Farming app (hereby referred to as
                        "Application") for mobile devices that was created by ECO NATURAL FARMING TECH PRIVATE
                        LIMITED (hereby referred to as "Service Provider") as a Free service. This service is
                        intended for use "AS IS".
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Information Collection and Use</h2>
                    <p>
                        The Application collects information when you download and use it. This information may
                        include:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>Your device's Internet Protocol address (e.g. IP address)</li>
                        <li>
                            The pages of the Application that you visit, the time and date of your visit, the time
                            spent on those pages
                        </li>
                        <li>The time spent on the Application</li>
                        <li>The operating system you use on your mobile device</li>
                    </ul>
                    <p>
                        The Application may also collect your device's approximate location to provide personalized
                        content and location-based services.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Location Information</h2>
                    <p>
                        The Application collects your device's approximate geographical location, which may be used
                        in the following ways:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>
                            <strong>Geolocation Services</strong>: To provide personalized content, relevant
                            recommendations, and location-based features.
                        </li>
                        <li>
                            <strong>Analytics and Improvements</strong>: Aggregated location data helps analyze user
                            behavior and improve Application performance.
                        </li>
                        <li>
                            <strong>Third-Party Services</strong>: The Service Provider may share anonymized location
                            data with external services to optimize and enhance Application offerings.
                        </li>
                    </ul>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Use of Information</h2>
                    <p>
                        The Service Provider may use the information you provided to contact you periodically for
                        important updates, notifications, or promotional purposes.
                    </p>
                    <p>
                        For a better experience, the Application may require you to provide personally identifiable
                        information, which will be retained by the Service Provider and used as described in this
                        privacy policy.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Third-Party Access</h2>
                    <p>
                        Only aggregated, anonymized data is periodically transmitted to external services to aid in
                        improving the Application and service. The Service Provider may share information with third
                        parties under the terms described here.
                    </p>
                    <p>
                        The Application utilizes third-party services that have their own privacy policies for
                        handling data. Below are links to the privacy policies of third-party services used by the
                        Application:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>
                            <a
                                href="https://www.google.com/policies/privacy/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                            >
                                Google Play Services
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://onesignal.com/privacy_policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                            >
                                OneSignal
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://expo.io/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                            >
                                Expo
                            </a>
                        </li>
                    </ul>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Disclosure of Information</h2>
                    <p>The Service Provider may disclose User Provided and Automatically Collected Information:</p>
                    <ul className="list-disc ml-6">
                        <li>As required by law, such as to comply with a subpoena or similar legal process.</li>
                        <li>
                            When they believe in good faith that disclosure is necessary to protect their rights, the
                            safety of users, or the public, investigate fraud, or respond to a government request.
                        </li>
                        <li>
                            With trusted service providers who work on their behalf, provided they adhere to the rules
                            of this privacy statement.
                        </li>
                    </ul>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Opt-Out Rights</h2>
                    <p>
                        You can stop all collection of information by uninstalling the Application. You may use
                        standard uninstall processes available on your mobile device or through the app marketplace.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Data Retention Policy</h2>
                    <p>
                        The Service Provider will retain User Provided data for as long as you use the Application
                        and for a reasonable time thereafter. If you wish to delete your User Provided Data, please
                        contact the Service Provider at naturalfarmingagri@gmail.com, and they will respond within a
                        reasonable timeframe.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Children's Privacy</h2>
                    <p>
                        The Service Provider does not knowingly collect personally identifiable information from
                        children under 13. In the event that the Service Provider discovers a child under 13 has
                        provided personal information, it will be promptly deleted from the servers. If you are a
                        parent or guardian and believe that your child has provided personal information, please
                        contact the Service Provider at naturalfarmingagri@gmail.com to take appropriate action.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Security</h2>
                    <p>
                        The Service Provider values the security of your information and provides physical,
                        electronic, and procedural safeguards to protect the data they process and maintain.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Changes to This Privacy Policy</h2>
                    <p>
                        This Privacy Policy may be updated from time to time. The Service Provider will notify you of
                        any changes by updating this page. You are advised to review this page regularly for any
                        changes, as continued use of the Application constitutes acceptance of any changes.
                    </p>
                    <br />

                    <p>This Privacy Policy is effective as of 2024-05-04</p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Your Consent</h2>
                    <p>
                        By using the Application, you consent to the processing of your information as described in
                        this Privacy Policy, now and as amended by the Service Provider.
                    </p>
                    <br />

                    <h2 className="text-xl font-bold mb-2">Contact Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy or data handling practices, please
                        contact the Service Provider at naturalfarmingagri@gmail.com.
                    </p>

                </div>
            </div>
        </section>
    );
};

export default NFPrivacyPolicy;
