import React from 'react';
import HomePageLayout from './Home/Homelayout';
import FeaturesSection from './Home/Featuresection';
import TestimonialsSection from './Home/Testimonialsection';
import CallToActionSection from './Home/Calltoactionsection';
import HomePageHero from './Home/HomePageHero';
import HomeProject from './Home/HomeProjects';
import FutureOfAI from './Home/FutureOfAI';
import HomeAboutUs from './Home/HomeAboutUs';
import HomeUseCases from './Home/HomeUseCase';

const Home = () => {
    return (
        <div> 
            <HomePageHero />
            <HomeProject />
            <HomeAboutUs />
            <HomeUseCases />
            {/* <HomePageLayout />
            <FeaturesSection /> */}
            <TestimonialsSection />
            <CallToActionSection />
        </div>
    );
};

export default Home;
