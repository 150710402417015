import React from 'react';

const HomeAboutUs = () => {
  return (
    <section className="pt-5 md:mt-12 overflow-hidden bg-gradient-to-r from-white via-white to-blue-50 md:pt-0 sm:pt-8 2xl:pt-8">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid items-center grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl">
              Generative AI Pioneers
            </h2>
            <p className="max-w-lg mt-3 text-xl leading-relaxed text-gray-600 dark:text-gray-300 md:mt-8">
              At GenAI Solutions, we are dedicated to transforming businesses with cutting-edge generative AI technologies. Our mission is to push the boundaries of AI to provide innovative solutions that drive efficiency, creativity, and growth.
            </p>

            <p className="mt-4 text-xl text-gray-600 dark:text-gray-300 md:mt-8">
              <span className="relative inline-block mr-2">
                <span className="absolute inline-block w-full bottom-0.5 h-2 bg-blue-500 dark:bg-gray-900"></span>
                <span className="relative"> GenAI Box </span>
              </span>
              can revolutionize your business operations and drive success.
            </p>

            <div className="mt-6">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">Our Standards</h3>
              <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">
                We adhere to the highest standards of coding practices to ensure robust, scalable, and maintainable solutions. Our code is meticulously reviewed and tested to meet the industry's best practices.
              </p>
            </div> 
          </div>

          <div className="relative">
            <img className="absolute inset-x-0 opacity-30 bottom-0 -mb-48 -translate-x-1/2 left-1/2" src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/blob-shape.svg" alt="Decorative Blob" />
            <img className="relative w-full xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/business-woman.png" alt="Team" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAboutUs;
