import { 
    SUBMIT_DATA_LOADING,
    SUBMIT_DATA_SUCCESS,
    SUBMIT_DATA_FAIL,

    GET_DATA_LOADING,
    GET_DATA_SUCCESS,
    GET_DATA_FAIL,
} from '../constants/allConstants'

export const submitReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBMIT_DATA_LOADING:
            return { loading: true }

        case SUBMIT_DATA_SUCCESS:
            return { loading: false, data: action.payload }

        case SUBMIT_DATA_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const getReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DATA_LOADING:
            return { loading: true }

        case GET_DATA_SUCCESS:
            return { loading: false, getData: action.payload }

        case GET_DATA_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}