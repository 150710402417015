import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { FILE_URL } from '../../../redux/env';

const MainContent = ({ toggleSidebar, contents, setActiveSection }) => {

    useEffect(() => {
        const handleScroll = () => {
            const contents = document.querySelectorAll('section');
            const scrollPosition = window.scrollY + 100; // Adjust for better detection

            contents.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                    setActiveSection(section.getAttribute('id'));
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="md:flex-1 w-full p-8 pb-80 bg-white">
                <button onClick={toggleSidebar} className="md:hidden mb-4 flex items-center text-gray-700">
                    <FaBars className="mr-2" /> <span>Menu</span>
                </button>

                {contents.map((section) => (
                    <section key={section.slug} id={section.slug} className="mb-16">
                        <h2 className="text-3xl font-extrabold text-gray-900">{section.title}</h2>
                        <div className="mt-4 text-lg text-gray-700 leading-relaxed"
                             dangerouslySetInnerHTML={{ __html: section.content }}>
                        </div>
                        {section.image && <div className='px-20'>
                            <img src={FILE_URL + section.image} alt="Invoice" className=" border-4 md:mt-12 rounded-xl justify-center rounded" />
                        </div>}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default MainContent;