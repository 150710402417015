import React from "react";
import { FILE_URL } from "../../../redux/env";

const ProjectDescription = ({ fullDescription, images }) => {
    return (
        <section>
            <div className="container mx-auto px-6 lg:px-8">
                <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
                <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
                    {images && images.map((getimage, index) => (
                        <div key={index} className="relative group bg-white p-4 rounded-lg shadow-lg ">
                            <img src={FILE_URL + getimage.image} alt="Project Screenshot" className="w-full" />
                            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                                <p className="text-white text-lg">{getimage.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ProjectDescription