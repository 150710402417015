import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import RouteList from './Common/RouteList';
import { AuthProvider } from './Common/AuthProvider';
import Loader from './Loader';
import useScrollToTop from './useScrollToTop';

function App() {
  
  useScrollToTop();



  return (
      <Provider store={store}>
        <AuthProvider>
          <RouteList />
          <Loader />
        </AuthProvider>
      </Provider> 
  );
}

export default App;
