import React from 'react';

const TermsAndConditions = () => {
    return (
        <section className="bg-gray-100 py-12">
            <div className="container mx-auto px-6 lg:px-8">
                <h1 className="text-4xl font-bold text-gray-900 mb-6">Terms & Conditions</h1>

                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                    <p className="mb-6">
                        Welcome to GenAI Box, a service provided by GenAI Box. These Terms and Conditions govern your use of our website and services. By accessing or using our service, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you must not use our services.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">2. Acceptance of Terms</h2>
                    <p className="mb-6">
                        By using the GenAI Box, you signify your acceptance of these Terms and Conditions and our Privacy Policy. If you do not agree to these Terms, please do not use our service. We may modify these Terms from time to time, and your continued use of the service will constitute acceptance of the updated Terms.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">3. Description of Service</h2>
                    <p className="mb-6">
                        GenAI Box provides an automated solution for extracting data from invoices. Our AI-powered technology ensures accurate data extraction, streamlining your workflow and enhancing productivity. The service includes features such as invoice scanning, data extraction, data validation, and integration with other accounting and ERP systems.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">4. User Responsibilities</h2>
                    <p className="mb-6">
                        As a user of our service, you are responsible for:
                    </p>
                    <ul className="list-disc list-inside mb-6">
                        <li>Ensuring that your use of the service complies with all applicable laws and regulations.</li>
                        <li>Providing accurate and up-to-date information when using the service.</li>
                        <li>Maintaining the confidentiality of your account information, including your username and password.</li>
                        <li>Promptly notifying us of any unauthorized use of your account or any other security breach.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold mb-4">5. Privacy Policy</h2>
                    <p className="mb-6">
                        Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose personally identifiable information from our users. By using our service, you consent to our collection and use of your data as outlined in our Privacy Policy.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">6. Intellectual Property</h2>
                    <p className="mb-6">
                        The content, design, and layout of the GenAI Box service are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without our prior written consent. All trademarks, logos, and service marks displayed on our service are the property of their respective owners.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
                    <p className="mb-6">
                        GenAI Box shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our service, including but not limited to damages for loss of profits, data, or other intangible losses. This limitation of liability applies whether the claim is based on warranty, contract, tort, or any other legal theory.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">8. Indemnification</h2>
                    <p className="mb-6">
                        You agree to indemnify, defend, and hold harmless GenAI Box, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of our service.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">9. Termination</h2>
                    <p className="mb-6">
                        We reserve the right to terminate or suspend your account and access to the service at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the service, us, or third parties, or for any other reason.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
                    <p className="mb-6">
                        These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which GenAI Box is headquartered, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in that jurisdiction.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">11. Changes to Terms</h2>
                    <p className="mb-6">
                        GenAI Box reserves the right to modify these Terms at any time. We will provide notice of changes by posting the updated Terms on our website. Your continued use of the service after any such changes constitutes your acceptance of the new Terms. We encourage you to review these Terms periodically for any updates or changes.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">12. Contact Information</h2>
                    <p className="mb-6">
                        If you have any questions or concerns about these Terms and Conditions, please contact us at:
                    </p>
                    <ul className="list-disc list-inside mb-6">
                        <li>Email: support@genaibox.io</li>
                        <li>Phone: (123) 456-7890</li>
                        <li>Address: 1234 AI Lane, Tech City, TC 56789</li>
                    </ul>

                    <h2 className="text-2xl font-semibold mb-4">13. Miscellaneous</h2>
                    <p className="mb-6">
                        These Terms constitute the entire agreement between you and GenAI Box regarding the use of our service. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">14. Third-Party Links</h2>
                    <p className="mb-6">
                        Our service may contain links to third-party websites or services that are not owned or controlled by GenAI Box. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that GenAI Box shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">15. User Feedback</h2>
                    <p className="mb-6">
                        We welcome and encourage you to provide feedback, comments, and suggestions for improvements to our service ("Feedback"). You may submit Feedback by emailing us at support@genaibox.io. Any Feedback you provide will be considered non-confidential and non-proprietary to you. By submitting Feedback, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">16. Data Security</h2>
                    <p className="mb-6">
                        We take data security seriously and implement appropriate technical and organizational measures to protect your data. However, you acknowledge that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security. You are responsible for maintaining the security of your account and for all activities that occur under your account.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">17. Disclaimer of Warranties</h2>
                    <p className="mb-6">
                        The service is provided on an "AS IS" and "AS AVAILABLE" basis. To the fullest extent permitted by applicable law, GenAI Box disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, and title. GenAI Box does not warrant that the service will be uninterrupted, error-free, or completely secure.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">18. Refund Policy</h2>
                    <p className="mb-6">
                        We offer a 30-day money-back guarantee for new subscribers. If you are not satisfied with our service, you may request a refund within 30 days of your initial purchase. To request a refund, please contact our support team at support@genaibox.io with your order details.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">19. Governing Language</h2>
                    <p className="mb-6">
                        These Terms and Conditions are originally written in English. In the event of any conflict between the English version and any translation, the English version shall prevail.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">20. Force Majeure</h2>
                    <p className="mb-6">
                        GenAI Box shall not be liable for any failure or delay in performance due to causes beyond our reasonable control, including but not limited to acts of God, war, terrorism, strikes, labor disputes, government regulations, shortages of materials, or communication or utility failures.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">21. Contact Us</h2>
                    <p className="mb-6">
                        If you have any questions about these Terms and Conditions, please contact us at:
                    </p>
                    <ul className="list-disc list-inside mb-6">
                        <li>Email: support@genaibox.io</li>
                        <li>Phone: (123) 456-7890</li>
                        <li>Address: 1234 AI Lane, Tech City, TC 56789</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default TermsAndConditions;
