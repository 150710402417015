import React from 'react';

const RegularLicense = () => {
    return (
        <div className="max-w-4xl mx-auto p-8 mt-8 mb-16 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">Regular License</h1>
            
            <h2 className="text-2xl font-bold text-gray-800 mt-8">The Essentials: What This License Covers</h2>
            <p className="mt-4 text-lg text-gray-700">
                1. <strong>License Grant:</strong> By purchasing a project from GenAI Box, you, the purchaser, are granted an ongoing, non-exclusive, worldwide license to use the digital project (referred to as "Item"). This license is detailed below, and by using the Item, you agree to these terms.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                2. <strong>Single Application:</strong> You are licensed to use the Item to create one End Product either for yourself or for one client (this is a “single application”). The End Product can be distributed for free.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                3. <strong>Definition of End Product:</strong>
                <ul className="list-disc ml-8 mt-2">
                    <li>
                        <strong>For Templates:</strong> An End Product is a customized implementation of the template. For example, if you purchase a website theme, the End Product is the final website you create with that theme, customized with your content.
                    </li>
                    <li className="mt-2">
                        <strong>For Other Items:</strong> An End Product is a larger work that incorporates the Item along with other elements, making it broader in scope and different in nature. For instance, if you purchase a button graphic, an End Product could be a website that uses that button graphic as part of the overall design.
                    </li>
                </ul>
            </p>

            <h2 className="text-2xl font-bold text-gray-800 mt-8">What You Can Do with the Item</h2>
            <p className="mt-4 text-lg text-gray-700">
                4. <strong>Client Transfer:</strong> You can create one End Product for a client and transfer that End Product to your client for a fee. Once transferred, the license is also transferred to your client.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                5. <strong>Copies and Distribution:</strong> You are allowed to make as many copies of the End Product as needed, as long as these copies are distributed for free.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                6. <strong>Modification and Derivatives:</strong> You can modify or manipulate the Item, combine it with other works, and create derivative works from it. However, the resulting works must comply with the terms of this license and fall within the definition of an End Product as described in section 3.
            </p>

            <h2 className="text-2xl font-bold text-gray-800 mt-8">Restrictions: What You Cannot Do with the Item</h2>
            <p className="mt-4 text-lg text-gray-700">
                7. <strong>Selling the End Product:</strong> You cannot sell the End Product, except to one client as mentioned in section 4. If you or your client wish to sell the End Product, you will need to purchase an Extended License.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                8. <strong>Redistribution Prohibition:</strong> You cannot redistribute the Item as stock, in a tool or template, or with source files, either on its own or bundled with other items. This applies even if the Item is modified. Redistribution is not allowed, even if it is offered for free.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                9. <strong>Prohibited Use in Customization Applications:</strong> You cannot use the Item in any application that allows an end user to customize a digital or physical product to their specific needs (e.g., "on demand", "made to order", or "build it yourself" applications). Each final product created by the application requires a separate license.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                10. <strong>No Standalone Components:</strong> Although you may modify the Item, you cannot extract and use individual components of the Item on a standalone basis outside of the End Product.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                11. <strong>End User Restrictions:</strong> You cannot allow end users of the End Product to extract the Item and use it separately from the End Product.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                12. <strong>Prohibited Use in Logos and Trademarks:</strong> The Item cannot be used as part of a logo, trademark, or service mark.
            </p>

            <h2 className="text-2xl font-bold text-gray-800 mt-8">Additional Terms: The Fine Print</h2>
            <p className="mt-4 text-lg text-gray-700">
                13. <strong>Lawful Use:</strong> The Item must only be used for lawful purposes. If the Item includes an image of a person, it must not be used in a way that creates a false identity, implies an endorsement, or in any way that is defamatory, obscene, or demeaning.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                14. <strong>Editorial Use:</strong> Items containing digital representations of real products, trademarks, or other intellectual property not released for commercial use are licensed for editorial use only. It is your responsibility to obtain any necessary clearances.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                15. <strong>Consistency with GenAI Box Terms:</strong> This license is part of the overall GenAI Box platform terms. In the event of any inconsistency between this license and the platform terms, this license will prevail to the extent necessary to resolve the inconsistency.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                16. <strong>Termination of License:</strong> This license can be terminated if you breach its terms. Upon termination, you must cease all use of the Item and remove it from any End Products.
            </p>
            <p className="mt-4 text-lg text-gray-700">
                17. <strong>Ownership and Licensing:</strong> GenAI Box retains ownership of the Item but grants you this license under the specified terms. This license is an agreement between you and GenAI Box.
            </p>
        </div>
    );
};

export default RegularLicense;
