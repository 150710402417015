import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactMessage } from '../../../redux/actions/authActions'; // Reusing the same contactMessage action
import Modal from '../../../Components/Modal';
import { useForm } from 'react-hook-form';

const DeleteAllDataRequest = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      // Attach 'delete all data' specific subject or message for the request
      const deleteAllDataRequestData = {
        ...data,
        subject: 'Delete All Data Request',
        message: data.message || 'Please delete all my personal data from your system.',
      };

      await dispatch(contactMessage(deleteAllDataRequestData)); // Reusing the same contactMessage action
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error('Failed to submit the request. Please try again.');
      toast.error('Failed to submit the request. Please try again.');
    }
  };

  const handleCloseModal = () => {
    setIsSubmitted(false);
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-4 md:py-16">
      <ToastContainer />
      <div className="container px-4 md:px-8 mx-auto">
        <div className="max-w-lg px-8 py-10 mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-900">
          <h3 className="inline-block px-3 py-1 mb-4 text-sm tracking-wider text-red-600 font-bold uppercase rounded-full bg-red-50">
            Delete All Data Request
          </h3>
          <h1 className="text-3xl font-semibold text-gray-800 capitalize dark:text-white">
            Request to Delete All Your Data
          </h1>
          <p className="mt-6 text-gray-700 dark:text-gray-400">
            If you wish to delete all your personal data from our system, please fill in the form below. We'll process your request as soon as possible.
          </p>
          <form className="mt-6 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Enter your name*</label>
              <input
                type="text"
                {...register('name', {
                  required: 'Name is required',
                  maxLength: {
                    value: 50,
                    message: 'Name must be less than 50 characters'
                  }
                })}
                placeholder="Your full name"
                className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
            </div>

            <div>
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address*</label>
              <input
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Email must be less than 100 characters'
                  }
                })}
                placeholder="Your registered email"
                className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
            </div>

            {/* Phone Input Field */}
            <div>
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Phone number*</label>
              <input
                type="text"
                {...register('phone', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Invalid phone number',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Phone number must be less than 15 characters'
                  }
                })}
                placeholder="Your phone number"
                className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              {errors.phone && <span className="text-red-500 text-sm">{errors.phone.message}</span>}
            </div>

            <div className="mt-6">
              <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Reason for data deletion (optional)</label>
              <textarea
                {...register('message', {
                  maxLength: {
                    value: 400,
                    message: 'Message must be less than 400 characters'
                  }
                })}
                className="block w-full h-24 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Let us know why you're requesting data deletion (optional)"
              ></textarea>
              {errors.message && <span className="text-red-500 text-sm">{errors.message.message}</span>}
            </div>

            <button className="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform rounded-full bg-gradient-to-r from-red-600 to-red-500 text-white hover:from-red-700 hover:to-red-600 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600">
              Request Data Deletion
            </button>
          </form>
        </div>
      </div>
      <Modal isOpen={isSubmitted} onClose={handleCloseModal}>
        <h2 className="text-2xl font-semibold mb-4">Request Submitted</h2>
        <p className="text-gray-700 mb-4">Your request to delete all your data has been successfully submitted. We will process it shortly.</p>
        <button
          onClick={handleCloseModal}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Close
        </button>
      </Modal>
    </section>
  );
};

export default DeleteAllDataRequest;
