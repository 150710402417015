import React from 'react';

const InvoAiHero = () => {
  return (
    <>
      <section className="py-14 lg:py-14 text-center bg-gradient-to-r from-blue-100 via-white to-blue-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-5 md:text-5xl">
          Transform Your <span className="text-blue-600">Invoice Management</span>
          </h1>
          <p className="text-base text-gray-500 font-semibold text-lg mb-5 max-w-md mx-auto">
          Streamline your invoicing process with cutting-edge AI technology. Automate, analyze, and visualize your invoices effortlessly.   
          </p>
        </div>
      </section>
    </>
  );
};
    
export default InvoAiHero;
