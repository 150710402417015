import React from 'react';

export const AboutUsSection = () => {
  return (
    <div className="bg-gray-50">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-24">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Why Choose Us
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                GenAI Box revolutionizes business operations with advanced generative AI solutions. Discover how we transform your processes with our cutting-edge technology.
              </p>
            </div>
            <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
              <div className="bg-white border-l-4 shadow-sm border-blue-600 rounded-lg">
                <div className="h-full p-5 border border-l-0 rounded-r-lg">
                  <h6 className="mb-2 font-semibold leading-5">
                    Standard Code Practices
                  </h6>
                  <p className="text-sm text-gray-900">
                    We adhere to the highest coding standards, ensuring robust, scalable, and maintainable solutions.
                  </p>
                </div>
              </div>
              <div className="bg-white border-l-4 shadow-sm border-blue-600 rounded-lg">
                <div className="h-full p-5 border border-l-0 rounded-r-lg">
                  <h6 className="mb-2 font-semibold leading-5">
                    Trusted by Leading Companies
                  </h6>
                  <p className="text-sm text-gray-900">
                    Our solutions are trusted by industry leaders to drive innovation and efficiency.
                  </p>
                </div>
              </div>
              <div className="bg-white border-l-4 shadow-sm border-blue-600 rounded-lg">
                <div className="h-full p-5 border border-l-0 rounded-r-lg">
                  <h6 className="mb-2 font-semibold leading-5">
                    Accurate Data Extraction
                  </h6>
                  <p className="text-sm text-gray-900">
                    Our AI ensures high accuracy in data extraction, capturing essential details swiftly and precisely.
                  </p>
                </div>
              </div>
              <div className="bg-white border-l-4 shadow-sm border-blue-600 rounded-lg">
                <div className="h-full p-5 border border-l-0 rounded-r-lg">
                  <h6 className="mb-2 font-semibold leading-5">
                    Seamless Integration
                  </h6>
                  <p className="text-sm text-gray-900">
                    Integrate GenAI Box with your systems effortlessly, enhancing workflows without disruptions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <img
              className="object-fill rounded-lg shadow-lg"
              src="images/about-1.jpg"
              alt="GenAI Box Technology"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
