import React, { useState } from 'react';
import ProjectDescription from './ProjectDescription';
import ProjectTechnical from './ProjectTechnical';
import ProjectFaq from './ProjectFaq'; 
import ProjectReview from './ProjectReview';



const ProjectDetail = ({projectDetails}) => {
    const [activeTab, setActiveTab] = useState(0);

    const useCases = [
        {
            title: 'Project Details',
            fullDescription: (
                projectDetails && <ProjectDescription fullDescription={projectDetails.description_detail} images={projectDetails.images}  />
            )
        },
        {
            title: 'Technical Specifications',
            fullDescription: (
                projectDetails && <ProjectTechnical technologies={projectDetails.technologies} />
            )
        },
        {
            title: 'Reviews',
            fullDescription: (
                projectDetails && <ProjectReview reviews={projectDetails.reviews} />   
            )
        },
        {
            title: 'FAQ',
            fullDescription: (
                projectDetails && <ProjectFaq faqs={projectDetails.faqs} />
            )
        },
    ];

    return (
        <section className="">
            <div className="mx-auto">
                <div>
                    <div className="flex justify-center mb-8 overflow-x-auto scrollbar-hide space-x-4">
                        {useCases.map((useCase, index) => (
                            <button
                                key={index}
                                className={`px-4 py-2 mx-2 text-md font-semibold whitespace-nowrap ${activeTab === index ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
                                onClick={() => setActiveTab(index)}
                            >
                                {useCase.title}
                            </button>
                        ))}
                    </div>
                    <div className="pt-8 pb-28 bg-gradient-to-t from-gray-100 via-white to-gray-100 rounded-lg text-left">
                        <div className="mt-4 text-gray-600">{useCases[activeTab].fullDescription}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectDetail;
