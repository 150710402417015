import React from 'react';
import InterviewAiHero from './InterviewAiHero';
import InterviewAiService from './InterviewAiService';
import ServiceDetailSection from './ServiceDetailSection';
import CallToActionSection from '../Home/Calltoactionsection';
import Divider from '../InvoAI/Divider';

const InterviewAi = () => {
  return (
    <> 
      <InterviewAiHero />
      <InterviewAiService />
      <Divider />
      <ServiceDetailSection />
      <CallToActionSection />
    </>
  );
};

export default InterviewAi;
