import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword } from '../../../redux/actions/authActions';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const { loading, error, message } = authState;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation
    if (!email) {
      setValidationError("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationError("Please enter a valid email address.");
      return;
    } else if (email.length > 100) {
      setValidationError("Email must be less than 100 characters.");
      return;
    } else {
      setValidationError('');
    }

    setIsSubmitting(true);

    const res = await dispatch(resetPassword({ email: email }));

    setIsSubmitting(false);

    if (res) {
      setEmail('');
      setSubmitted(true);
      setNotFound(false);
    } else {
      setSubmitted(false);
      setNotFound(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
      <div className="max-w-screen-xl m-0 sm:m-10 flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-white shadow-lg rounded-lg">
          <div className="mt-4 flex flex-col items-center">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Forgot Password
            </h1>
            <p className="text-gray-600 mt-2">
              Enter your email to receive a password reset link.
            </p>
            <div className="w-full flex-1 mt-8">
              <div className="mx-auto max-w-xs">
                <form onSubmit={handleSubmit}>
                  <input
                    className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    maxLength={100}
                  />
                  {validationError && (
                    <p className="text-red-500 mt-2 text-sm">{validationError}</p>
                  )}
                  <button
                    type="submit"
                    className={`mt-5 tracking-wide font-semibold text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600'}`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <>
                        <svg
                          className="w-6 h-6 -ml-2"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                          <circle cx="8.5" cy="7" r="4" />
                          <path d="M20 8v6M23 11h-6" />
                        </svg>
                        <span className="ml-3">Send Reset Link</span>
                      </>
                    )}
                  </button>
                </form>
                {loading && <p className="mt-4">Loading...</p>}
                {error && <p className="text-red-500 mt-4">{error}</p>}
                {message && <p className="text-green-500 mt-4">{message}</p>}
                {submitted && !error && !loading && (
                  <p className="text-green-500 mt-4">A reset link has been sent to your email.</p>
                )}
                {notFound && !error && !loading && (
                  <p className="text-red-500 mt-4">Email is not found.</p>
                )}
                <div className="mt-6 text-center">
                  <Link to="/signin" className="text-sm font-medium text-blue-500 hover:text-blue-700">
                    Back to Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
