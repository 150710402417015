import React from 'react';
import TabView from './TabView';
import ProfileHead from './ProfileHead';

const Profile = () => {
  return (
    <div className=' min-h-screen bg-gray-100 pb-24'>
        <ProfileHead />
        <TabView />
    </div>
  );
};

export default Profile;
