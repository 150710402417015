import React from 'react';

const ContactHero = () => {
  return (
    <section className="py-14 lg:py-24 text-center bg-gradient-to-r from-blue-100 via-white to-blue-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-5 md:text-5xl">
          Get in Touch with <span className="text-blue-600">GenAI Box</span>
        </h1>
        <p className="text-lg text-gray-600 font-semibold mb-6 max-w-2xl mx-auto">
          Have any questions or need assistance? Our team is here to help you with all your AI solutions. Reach out to us today and discover how GenAI Box can transform your business!
        </p>
      </div>
    </section>
  );
};

export default ContactHero;
