import React from 'react';

const ServiceHeroSection = () => {
  return (
    <section className="py-14 text-center bg-gradient-to-r from-blue-100 via-white to-blue-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-5 md:text-5xl">
          Empower Your Business with <span className="text-blue-600">GenAI Box</span>
        </h1>
        <p className="text-lg text-gray-600 font-semibold text-lg mb-6 max-w-2xl mx-auto">
          Harness the potential of our AI-driven solutions to automate your invoicing process and gain deep insights through data visualization. 
          Experience unparalleled efficiency and accuracy, transforming the way you handle financial operations.
        </p>
      </div>
    </section>
  );
};

export default ServiceHeroSection;
