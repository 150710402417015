import React from 'react';

const CallToAction = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-blue-100 via-white to-blue-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 md:text-4xl">
          Have Questions? <span className="text-blue-600">We're Here to Help!</span>
        </h2>
        <a
          href="/contact"
          className="inline-block px-8 py-3 text-sm font-semibold text-white bg-blue-600 rounded-full shadow hover:bg-blue-500 transition duration-300"
        >
          Contact Us
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
