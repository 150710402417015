import React from 'react';
import "../../../style/ModalStyle.css";  // Ensure to import the CSS for additional styles

const ExcitingMessageModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-[100]">
      <div className="bg-white p-8 rounded-lg text-center shadow-lg relative overflow-hidden">
        <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
          {Array.from({ length: 100 }).map((_, index) => (
            <div key={index} className={`confetti confetti-${index % 10}`}></div>
          ))}
        </div>
        <h2 className="text-4xl font-bold mb-4 animate-bounce">🎉 Congratulations! 🎉</h2>
        <p className="text-lg font-semibold mb-2">You have successfully purchased the GenAI Box project!</p>
        <p className="text-lg font-semibold mb-4">We are excited to have you on board. Click the button below to download your project.</p>
        <a href="/download" className="inline-block mt-6 bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 py-3 rounded-full mb-4 hover:from-blue-700 hover:to-blue-800 ">Download Project</a>
      </div>
    </div>
  );
};

export default ExcitingMessageModal;
