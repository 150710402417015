import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LiaTimesSolid } from "react-icons/lia";
import { FaCloudDownloadAlt, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { IoCartOutline } from "react-icons/io5";
import { CartContext } from '../../Common/CartContext';
import { useAuth } from '../../Common/AuthProvider';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { cart } = useContext(CartContext);
  const { logout } = useAuth();


  const location = useLocation();
  const totalItems = cart.length;
  
  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'text-blue-600' : 'text-slate-700';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#dropdownHoverButton") && !event.target.closest("#dropdownHover") && !event.target.closest("#mobileMenu")) {
        setIsDropdownOpen(false);
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userActive = localStorage.getItem('S%$FO*$#S7');

  return (
    <header className="py-3 relative z-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative z-50 flex justify-between items-center">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src="/images/logo.png" className="h-8 md:h-12" alt="Logo" />
              <span className="self-center text-2xl font-extrabold whitespace-nowrap dark:text-white">
                GenAI Box
              </span>
            </Link>
          </div>
          <div className="flex-1 flex justify-center">
            <div className="hidden md:flex md:gap-x-6">
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/about')} hover:bg-slate-100 hover:text-slate-900`} to="/about">Our Story</Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/solution')} hover:bg-slate-100 hover:text-slate-900`} to="/solution">Solutions</Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/contact')} hover:bg-slate-100 hover:text-slate-900`} to="/contact">Support</Link>
              {/* <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/blogs')} hover:bg-slate-100 hover:text-slate-900`} to="/blogs">Updates</Link> */}
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {totalItems > 0 && (<Link to="/cart" className="relative">
              <IoCartOutline className="text-2xl text-slate-700 hover:text-blue-600" />
              
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-blue-600 text-white text-xs font-semibold rounded-full h-5 w-5 flex items-center justify-center">
                  {totalItems}
                </span>
            </Link>)}
            {!userActive ? (
              <>
                <div className="hidden md:block">
                  <Link className={`inline-block font-semibold rounded-lg px-2 py-1 ${activeLink('/signin')} text-md hover:bg-slate-100 hover:text-slate-900`} to="/signin">Sign in</Link>
                </div>
                <Link className="hidden md:inline group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600" to="/signup">
                  <span>Get started <span className="hidden lg:inline">today</span></span>
                </Link>
              </>
            ) : (
              <div className="relative">
                <button
                  id="dropdownHoverButton"
                  onClick={toggleDropdown}
                  className="group hidden md:inline-flex items-center justify-center rounded-full py-2 -mr-4 px-3 md:px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
                  type="button"
                >
                  Dashboard
                  <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                  </svg>
                </button>

                {isDropdownOpen && (
                  <div
                    id="dropdownHover"
                    className="absolute hidden md:flex right-0 z-10 mt-2 bg-white rounded-lg shadow-lg w-56 dark:bg-gray-800"
                  >
                    <ul className="py-2 w-full m-2 text-sm text-gray-700 dark:text-gray-200">
                      <li>
                        <Link
                          to="/download"
                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-blue-100 to-blue-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <FaCloudDownloadAlt className="w-5 h-5 text-gray-500 dark:text-gray-300" />
                            <span>Download</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/settings"
                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-green-100 to-green-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <FaCog className="w-5 h-5 text-gray-500 dark:text-gray-300" />
                            <span>Settings</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link

                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-red-100 to-red-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => {setIsDropdownOpen(false); logout();}}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <FaSignOutAlt className="w-5 h-5 text-gray-500 dark:text-gray-300" />
                            <span>Sign out</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}



              </div>
            )}

            <div className="-mr-1 md:hidden">
              <button onClick={toggleMobileMenu} className="relative z-10 flex h-8 w-8 items-center justify-center">
                <svg aria-hidden="true" className="h-3.5 w-3.5 overflow-visible stroke-slate-700" fill="none" strokeWidth="2" strokeLinecap="round">
                  <path d={isMobileMenuOpen ? "" : "M0 1H14M0 7H14M0 13H14"} className="origin-center transition" />
                </svg>
              </button>
            </div>
          </div>
        </nav>
        {isMobileMenuOpen && (
          <div className="fixed inset-0 z-50 flex items-left justify-left bg-gray-800 bg-opacity-75 transition-opacity">
            <div id="mobileMenu" className="bg-white p-6 shadow-lg w-3/4">
              <button onClick={toggleMobileMenu} className="absolute top-4 right-4 text-white hover:text-gray-700">
                <LiaTimesSolid className="w-5 h-5" />
              </button>
              <Link to="/" className="flex items-center space-x-3 mb-4 rtl:space-x-reverse">
                <img src="/images/logo.png" className="h-12" alt="Logo" />
                <span className="self-center text-2xl font-extrabold whitespace-nowrap dark:text-white">
                  GenAI
                </span>
              </Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/about" onClick={toggleMobileMenu}>Our Story</Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/solution" onClick={toggleMobileMenu}>Solutions</Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/contact" onClick={toggleMobileMenu}>Support</Link>
              {!userActive ? (
                <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/signin" onClick={toggleMobileMenu}>Sign in</Link>
              ) : (
                <>
                  <Link to="/download" className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" onClick={toggleMobileMenu}>Download</Link>
                  <Link to="/settings" className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" onClick={toggleMobileMenu}>Settings</Link>
                  <Link to="/signout" className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900">Sign out</Link>
                </>)}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
