import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProjects } from '../../../redux/actions/projectActions';
import { FILE_URL } from '../../../redux/env';

const HomeProject = () => {
  const [projectList, setProjectList] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects()).then((data) => {
      setProjectList(data);
    });
  }, [dispatch]);

  return (
    <div className="container mx-auto px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="text-left mb-12">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our AI-Powered Solutions
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Explore our innovative AI-driven products designed to enhance and streamline business operations across various sectors.
        </p>
      </div>
      <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-2">
        {projectList && projectList.map((project) => (
          <div key={project.id} className="transition transform hover:-translate-y-2 duration-300">
            <Link to={project.technology && project.technology}>
              <div className="max-w-full bg-gradient-to-r from-blue-100 to-gray-200 hover:from-gray-400 hover:to-blue-400 bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-2xl">
                <div className="m-4 bg-white rounded-xl overflow-hidden">
                  <img className="object-cover" src={FILE_URL + project.image} alt={project.name} />
                  <div className="p-4 border-t-2 text-center border-gray-300 bg-white">
                    <h3 className="mb-1 text-lg font-bold text-gray-900">{project.name}</h3>
                    {/* <div className="flex flex-wrap max-w-full justify-center text-gray-700">
                      {project.technology && project.technology.split(',').map((tech, index) => (
                        <p key={index} className="bg-gradient-to-r from-blue-50 to-gray-200 font-semibold mx-2 my-2 md:my-1 py-1 px-2 rounded-full text-xs md:text-sm">{tech}</p>
                      ))}
                    </div> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeProject;
