export const PROJECT_LIST_REQUEST = 'PROJECT_LIST_REQUEST';
export const PROJECT_LIST_SUCCESS = 'PROJECT_LIST_SUCCESS';
export const PROJECT_LIST_FAIL = 'PROJECT_LIST_FAIL';

export const PROJECT_DETAILS_REQUEST = 'PROJECT_DETAILS_REQUEST';
export const PROJECT_DETAILS_SUCCESS = 'PROJECT_DETAILS_SUCCESS';
export const PROJECT_DETAILS_FAIL = 'PROJECT_DETAILS_FAIL';

export const PURCHASED_PROJECT_REQUEST = 'PURCHASED_PROJECT_REQUEST';
export const PURCHASED_PROJECT_SUCCESS = 'PURCHASED_PROJECT_SUCCESS';
export const PURCHASED_PROJECT_FAIL = 'PURCHASED_PROJECT_FAIL';

export const DOWNLOAD_PROJECT_REQUEST = 'DOWNLOAD_PROJECT_REQUEST';
export const DOWNLOAD_PROJECT_SUCCESS = 'DOWNLOAD_PROJECT_SUCCESS';
export const DOWNLOAD_PROJECT_FAIL = 'DOWNLOAD_PROJECT_FAIL';

export const PROJECT_SUPPORT_REQUEST = 'PROJECT_SUPPORT_REQUEST';
export const PROJECT_SUPPORT_SUCCESS = 'PROJECT_SUPPORT_SUCCESS';
export const PROJECT_SUPPORT_FAIL = 'PROJECT_SUPPORT_FAIL';

export const PROJECT_DOCUMENT_REQUEST = 'PROJECT_DOCUMENT_REQUEST';
export const PROJECT_DOCUMENT_SUCCESS = 'PROJECT_DOCUMENT_SUCCESS';
export const PROJECT_DOCUMENT_FAIL = 'PROJECT_DOCUMENT_FAIL';

export const BLOGS_REQUEST = 'BLOGS_REQUEST';
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS';
export const BLOGS_FAIL = 'BLOGS_FAIL';

export const GET_BLOG_REQUEST = 'GET_BLOG_REQUEST';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';

export const GET_BLOG_BYCATEGORY_REQUEST = 'GET_BLOG_BYCATEGORY_REQUEST';
export const GET_BLOG_BYCATEGORY_SUCCESS = 'GET_BLOG_BYCATEGORY_SUCCESS';
export const GET_BLOG_BYCATEGORY_FAIL = 'GET_BLOG_BYCATEGORY_FAIL';