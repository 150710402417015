import React from 'react';

const StatCard = ({ value, description, details }) => (
  <div className="bg-white p-6 rounded-2xl shadow-md w-full lg:w-1/3 mx-auto">
    <div className="flex gap-5">
      <div className="text-4xl font-bold text-blue-600">{value}</div>
      <div>
        <h4 className="text-xl font-semibold text-gray-900 mb-2">{description}</h4>
        <p className="text-xs text-gray-500">{details}</p>
      </div>
    </div>
  </div>
);

const StatisticsSection = () => {
  return (
    <section className="py-14 lg:pt-14 lg:pb-24 bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-14">Our Impact in Numbers</h2>
        <div className="flex flex-col lg:flex-row gap-5 xl:gap-8 justify-between">
          <StatCard value="500+" description="Satisfied Clients" details="Companies leveraging our AI solutions for success." />
          <StatCard value="1000+" description="Projects Delivered" details="Successful AI projects completed and delivered." />
          <StatCard value="10M+" description="Data Points Analyzed" details="Data points processed and analyzed with high accuracy." />
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
