import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

const Sidebar = ({ sidebarOpen, toggleSidebar, contents, activeSection }) => {
    const [openSection, setOpenSection] = useState('installation'); // Default to 'installation' open

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? '' : section);
    };

    const getActiveClass = (sectionId) => activeSection === sectionId ? 'bg-gray-200 text-blue-600' : 'text-gray-900';

    // Filter contents based on the type value
    const installationContents = contents.filter((section) => section.type === 0);
    const usageContents = contents.filter((section) => section.type === 1);

    return (
        <div>
            {/* Sidebar for Desktop */}
            <div className="hidden md:block w-64 h-screen fixed top-20 left-0 bg-white z-30 overflow-y-auto">
                <nav className="px-6 pt-8 py-2">
                    <div className="space-y-2">
                        <div>
                            <button
                                onClick={() => toggleSection('installation')}
                                className="w-full flex justify-between items-center px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                            >
                                <span className="text-xs font-semibold uppercase text-gray-500">Installation</span>
                                {openSection === 'installation' ? <FaChevronUp className='text-gray-500' /> : <FaChevronDown className='text-gray-500' />}
                            </button>
                            {openSection === 'installation' && (
                                <ul className="mt-3 space-y-2 bg-gray-50 p-2 rounded-lg">
                                    {installationContents.map((section) => (
                                        <li key={section.slug}>
                                            <a href={`#${section.slug}`} className={`block px-3 py-2 hover:bg-gray-100 rounded-lg ${getActiveClass(section.slug)}`}>
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>
                            <button
                                onClick={() => toggleSection('usage')}
                                className="w-full flex justify-between items-center px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                            >
                                <span className="text-xs font-semibold uppercase text-gray-500">Usage</span>
                                {openSection === 'usage' ? <FaChevronUp className='text-gray-500' /> : <FaChevronDown className='text-gray-500' />}
                            </button>
                            {openSection === 'usage' && (
                                <ul className="mt-3 space-y-2 bg-gray-50 p-2 rounded-lg">
                                    {usageContents.map((section) => (
                                        <li key={section.slug}>
                                            <a href={`#${section.slug}`} className={`block px-3 py-2 hover:bg-gray-100 rounded-lg ${getActiveClass(section.slug)}`}>
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </nav>
            </div>

            {/* Sidebar for Mobile */}
            <div className={`md:hidden fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleSidebar}></div>
            <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-white border-r shadow-lg transform transition-transform duration-300 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className="flex justify-between items-center p-4 border-b">
                    <h1 className="text-lg font-semibold text-gray-900">Menu</h1>
                    <button onClick={toggleSidebar} className="text-gray-700">
                        <FaTimes size={20} />
                    </button>
                </div>
                <nav className="px-6 pt-8 py-2">
                    <div className="space-y-2">
                        <div>
                            <button
                                onClick={() => toggleSection('installation')}
                                className="w-full flex justify-between items-center px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                            >
                                <span className="text-xs font-semibold uppercase text-gray-500">Installation</span>
                                {openSection === 'installation' ? <FaChevronUp className='text-gray-500' /> : <FaChevronDown className='text-gray-500' />}
                            </button>
                            {openSection === 'installation' && (
                                <ul className="mt-3 space-y-2 bg-gray-50 p-1 rounded-lg">
                                    {installationContents.map((section) => (
                                        <li key={section.slug}>
                                            <a href={`#${section.slug}`} className={`block px-3 py-2 hover:bg-gray-100 rounded-lg ${getActiveClass(section.slug)}`} onClick={toggleSidebar}>
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>
                            <button
                                onClick={() => toggleSection('usage')}
                                className="w-full flex justify-between items-center px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                            >
                                <span className="text-xs font-semibold uppercase text-gray-500">Usage</span>
                                {openSection === 'usage' ? <FaChevronUp className='text-gray-500' /> : <FaChevronDown className='text-gray-500' />}
                            </button>
                            {openSection === 'usage' && (
                                <ul className="mt-3 space-y-2 bg-gray-50 p-1 rounded-lg">
                                    {usageContents.map((section) => (
                                        <li key={section.slug}>
                                            <a href={`#${section.slug}`} className={`block px-3 py-2 hover:bg-gray-100 rounded-lg ${getActiveClass(section.slug)}`} onClick={toggleSidebar}>
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;
