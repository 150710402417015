import React, { useContext, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { CartContext } from '../../../Common/CartContext';
import { Link, useNavigate } from 'react-router-dom';

const CartPage = () => {
  const [codeError, setCodeError] = useState(false);
  const [codeInput, setCodeInput] = useState('');
  const { cart, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  const totalAmount = cart ? cart.reduce((acc, item) => {
    return acc + item.price;
  }, 0) : 0;

  const checkOut = () => {
    localStorage.setItem('previous_url', '/checkout')
    navigate('/checkout');
  }

  return (
    <div className="min-h-screen bg-gray-100 py-10">
      <div className="container mx-auto p-4">
        <nav className="text-gray-700 mb-4">
          <Link to="/" className="text-gray-700 font-semibold">Home</Link> / <span className="text-blue-700 font-semibold">Cart</span>
        </nav>
        {cart && cart.length > 0 ? (
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-8">
              {cart.map((item) => (
                <div key={item.id} className="flex p-4 bg-white rounded-lg shadow-md">
                  <div className="flex items-center md:w-5/6">
                    <img
                      src="/images/project-screenshot-1.png"
                      alt="Project"
                      className="w-32 h-32 p-2 border-2 border-gray-200 rounded-md object-cover"
                    />
                    <div className="ml-4 flex flex-col justify-between">
                      <div>
                        <h3 className="text-xl font-semibold text-gray-700">InvoAI Invoice Extractor</h3>
                        <p className="text-gray-500 font-semibold my-1">
                          Price: <span className="text-blue-700">${item.price}</span>{' '}
                          {item.oldPrice && <span className="line-through">${item.oldPrice}</span>}
                        </p>
                        <p className="text-gray-500 font-semibold">
                          License: <span className="text-blue-600">{item.license}</span>
                        </p>
                        <p className="text-gray-500 font-semibold">
                          Version: <span className="text-gray-400">{item.version}.0</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => removeFromCart(item.id)}
                      className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-red-500 to-red-600 text-white hover:text-slate-100 hover:from-red-600 hover:to-red-700 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600"
                    >
                      <FaRegTrashAlt />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-span-4 mt-6 md:mt-0">
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
                <div className="flex justify-between mb-2">
                  <p className="text-gray-500 font-semibold">Price</p>
                  <p className="text-gray-700 font-semibold">${totalAmount.toFixed(2)}</p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className="text-gray-500 font-semibold">Handling Charges</p>
                  <p className="text-green-500 font-semibold">Free</p>
                </div>
                <div className="flex justify-between mb-4">
                  <p className="text-gray-500 font-semibold">Total</p>
                  <p className="text-gray-700 font-semibold">${totalAmount.toFixed(2)}</p>
                </div>
                <div className="flex justify-center mb-4">
                  <button onClick={()=>checkOut()} className="group inline-flex items-center justify-center rounded-full py-2 px-12 my-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">
                    Checkout
                  </button>
                </div>
                <div className="my-4">
                  <h4 className="text-md font-semibold mb-2">Apply Coupon Code</h4>
                  <p className="text-gray-500 mb-2">Have a coupon code? Apply in this field and fill out!</p>
                  <div className="flex">
                    <input
                      type="text"
                      value={codeInput}
                      onChange={(e) => setCodeInput(e.target.value)}
                      className="flex-1 px-4 py-2 border rounded-l-lg"
                      placeholder="Code"
                    />
                    <button onClick={() => setCodeError(true)} className="bg-gray-700 text-white px-4 py-2 rounded-r-lg">
                      Apply
                    </button>
                  </div>
                  {codeError && <small className="text-red-500 ml-2 font-semibold">This code is not valid!</small>}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-700 my-20 font-semibold">
            <p className="text-3xl font-bold mb-4">Your cart is empty!</p>
            <p className="text-lg mb-8">Browse our categories and discover our best deals!</p>
            <Link
              to="/"
              className="inline-flex items-center justify-center rounded-full py-3 px-6 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
            >
              Buy Projects
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartPage;
