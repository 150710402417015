import React, { useEffect, useState } from 'react'; 
import { decryptData } from '../../../utils/encryption';

const ProfileHead = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = decryptData(localStorage.getItem('H$S%#PR32S'));
    setUser(user);
  }, []);

  
  return (
    <section className="">
      <div className="bg-gradient-to-r mb-12 from-blue-100 via-cyan-100 to-blue-100 py-8 md:py-4 md:px-36 max-w-full space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
        <img className="block mx-auto h-24 rounded-full opacity-50 sm:mx-0 sm:shrink-0" src="images/user-icon.jpg" alt="User's Face" />
        <div className="text-center space-y-2 sm:text-left">
          <div className="space-y-0.5">
            <p className="text-lg text-black font-semibold">{user && user.first_name + user.last_name}</p>
            <p className="text-slate-500 font-medium">{user.email}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileHead;
