    import React from 'react';
    import { FaCheckCircle, FaSyncAlt, FaCode, FaShieldAlt, FaUserAlt, FaCogs, FaLongArrowAltRight } from 'react-icons/fa';
    import { Link } from 'react-router-dom';

    const InvoAiService = () => {
        return (
            <section className="overflow-hidden bg-white py-8">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pl-8 lg:pt-4 flex items-center">
                            <div className="lg:max-w-lg">
                                <h2 className="inline-block px-3 py-1 text-sm tracking-wider text-blue-600 font-bold uppercase rounded-full bg-blue-50">Enhance Efficiency</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AI-Powered Invoice Processing</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Our cutting-edge AI technology automates your invoice processing, saving you time and reducing errors.
                                </p>
                                <div className="mt-10 space-y-6">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <span className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-100">
                                                <FaCheckCircle className="h-4 w-4 text-blue-500" />
                                            </span>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg leading-6 font-medium text-gray-900">Accurate Data Extraction</h4>
                                            <p className="mt-2 text-base text-gray-600">
                                                Utilize our AI to ensure accurate and efficient data extraction from your invoices.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <span className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-100">
                                                <FaSyncAlt className="h-4 w-4 text-blue-500" />
                                            </span>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg leading-6 font-medium text-gray-900">Effortless Automation</h4>
                                            <p className="mt-2 text-base text-gray-600">
                                                Easily integrate our solution with your existing systems and workflows.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <span className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-100">
                                                <FaCode className="h-4 w-4 text-blue-500" />
                                            </span>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg leading-6 font-medium text-gray-900">Developer-Friendly API</h4>
                                            <p className="mt-2 text-base text-gray-600">
                                                Our API is well-documented and easy to use, making integration a breeze for developers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <Link to="/signup"
                                        className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Get Started Now</Link>
                                    <Link to="/contact" className="flex rounded-md bg-white-600 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-lg hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Connect with us<span aria-hidden="true"><FaLongArrowAltRight size={21} className='ml-1 text-gray-600' /></span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <img src="images/service-1.png" alt="Product screenshot" className="max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 w-full  md:-ml-4 lg:-ml-0" />
                        </div>
                    </div>
                </div>

                <div className="bg-white mt-16">
                    <div className="max-w-7xl mx-auto">
                        <div className="container mx-auto px-6 p-6 pt-0 bg-white">
                            <div className="flex flex-wrap my-12">
                                <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 p-8">
                                    <div className="flex items-center mb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="h-6 w-6 text-blue-700"
                                        >
                                            <path
                                                d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"
                                            />
                                        </svg>
                                        <div className="ml-4 text-base font-semibold">Automated Data Extraction</div>
                                    </div>
                                    <p className="leading-loose text-gray-500">
                                        Automatically extract key data from your invoices with high accuracy using our AI-driven technology.
                                    </p>
                                </div>

                                <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r p-8">
                                    <div className="flex items-center mb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="h-6 w-6 text-blue-700"
                                        >
                                            <path
                                                d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"
                                            />
                                        </svg>
                                        <div className="ml-4 text-base font-semibold">Enterprise-Ready Solutions</div>
                                    </div>
                                    <p className="leading-loose text-gray-500">
                                        Scale your business effortlessly with our robust, enterprise-ready invoice extraction solutions.
                                    </p>
                                </div>

                                <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 p-8">
                                    <div className="flex items-center mb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="h-6 w-6 text-blue-700"
                                        >
                                            <path
                                                d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"
                                            />
                                        </svg>
                                        <div className="ml-4 text-base font-semibold">Seamless Integration</div>
                                    </div>
                                    <p className="leading-loose text-gray-500">
                                        Integrate our invoice extraction technology seamlessly with your existing systems and workflows.
                                    </p>
                                </div>

                                <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0 p-8">
                                    <div className="flex items-center mb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="h-6 w-6 text-blue-700"
                                        >
                                            <path
                                                d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"
                                            />
                                        </svg>
                                        <div className="ml-4 text-base font-semibold">Highly Secure</div>
                                    </div>
                                    <p className="leading-loose text-gray-500">
                                        Keep your data secure with our robust security measures and compliance with industry standards.
                                    </p>
                                </div>

                                <div className="w-full border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0 p-8">
                                    <div className="flex items-center mb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="h-6 w-6 text-blue-700"
                                        >
                                            <path
                                                d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"
                                            />
                                        </svg>
                                        <div className="ml-4 text-base font-semibold">User-Friendly Interface</div>
                                    </div>
                                    <p className="leading-loose text-gray-500">
                                        Our platform features a user-friendly interface that makes it easy to manage and track your invoices.
                                    </p>
                                </div>

                                <div className="w-full md:w-1/2 lg:w-1/3 p-8">
                                    <div className="flex items-center mb-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="h-6 w-6 text-blue-700"
                                        >
                                            <path
                                                d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13 13-5.8 13-13c0-1.398-.188-2.793-.688-4.094L26.688 13.5c.2.8.313 1.602.313 2.5 0 6.102-4.898 11-11 11S5 22.102 5 16 9.898 5 16 5c3 0 5.695 1.195 7.594 3.094L25 6.688C22.7 4.386 19.5 3 16 3zm11.281 4.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12z"
                                            />
                                        </svg>
                                        <div className="ml-4 text-base font-semibold">Customizable Workflows</div>
                                    </div>
                                    <p className="leading-loose text-gray-500">
                                        Tailor our invoice extraction workflows to fit your unique business needs and processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        );
    };

    export default InvoAiService;
