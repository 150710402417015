import React, { useEffect, useState } from 'react';
import ChatBox from './ChatBox';
import { getprojectSupports } from '../../../redux/actions/projectActions';
import { useDispatch } from 'react-redux';
import axiosInstance, { axiosInstanceWithMultipart } from '../../../Common/axiosInstance';
import { decryptData } from '../../../utils/encryption';

const ChatPage = () => {
    const [selectedProject, setSelectedProject] = useState('');
    const [projects, setProjects] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getprojectSupports())
          .then((data) => {
            if (data.projects.length > 0) {
                setSelectedProject(data.projects[0].id);
                setProjects(data.projects);
                setChatMessages(data.tickets);
                filterMessages(data.tickets, data.projects[0].id);
            }
          })
          .catch((error) => {
            console.error("Error fetching user orders:", error);
          });
    }, [dispatch]);

    const filterMessages = (messages, projectId) => {
        const projectMessages = messages.filter(
            (message) => message.order === parseInt(projectId)
        );
        setFilteredMessages(projectMessages);
    };

    const handleProjectChange = (e) => {
        const projectId = e.target.value;
        setSelectedProject(projectId);
        filterMessages(chatMessages, projectId);
    };

    const addMessage = async (message, project) => {
        const userDetail = decryptData(localStorage.getItem('H$S%#PR32S')); 
        const config = axiosInstanceWithMultipart();
        
        await axiosInstance.post('/create-support/', {
            ...message,
            order: project, // Ensure you are sending the project ID correctly
            user: userDetail.id,
        }, config).then(response => {
            const updatedMessages = [...chatMessages, response.data];
            setChatMessages(updatedMessages);
            filterMessages(updatedMessages, project);
        }).catch(error => {
            console.error("Error sending message:", error);
        });
    };

    return (
        <div className="flex flex-col md:max-h-screen bg-gray-100"> 
            <div className="flex-grow flex justify-center items-center">
                {selectedProject ? (
                    <div className="w-full bg-white rounded-lg shadow flex flex-col overflow-hidden">
                        <div className="bg-gray-300 text-gray-600 px-6 border-b-2 border-gray-400 py-4 md:flex justify-center items-center">
                            <h2 className="text-xl font-semibold mb-4 mr-4 md:mb-0">Project </h2>
                            <select
                                value={selectedProject}
                                onChange={handleProjectChange}
                                className=" px-2 font-semibold py-3 bg-white text-gray-700 rounded-lg shadow focus:outline-none"
                            >
                                <option value="" disabled>Select a project</option>
                                {projects.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.project_name + " " + project.project_title + " " + project.project_version}    
                                    </option>
                                ))}
                            </select>
                        </div>
                        <ChatBox project={selectedProject} messages={filteredMessages} addMessage={addMessage} />
                    </div>
                ) : (
                    <div className="text-gray-600 p-8 text-center">
                        <p>Please select a project to start the chat.</p>
                        <select
                            value={selectedProject}
                            onChange={handleProjectChange}
                            className="mt-4 p-2  font-semibold bg-white text-gray-700 rounded-lg shadow focus:outline-none"
                        >
                            <option value="" disabled>Select a project</option>
                            {projects.map((project) => (
                                <option key={project.id} value={project.id}>
                                    {project.project_name + " " + project.project_title + " " + project.project_version}    
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatPage;
