import React from 'react';
import { FILE_URL } from '../../../redux/env';

const Message = ({ message }) => {
    return (
        <div> 
            {(message.user_question || message.user_que_image) && (<div className={`flex justify-end mb-2`}>
                <div className={`p-2 rounded-lg bg-blue-100 text-black`}>
                    {message.user_question && <p className="max-w-xl">{message.user_question}</p>}
                    {message.user_que_image && (
                        <img
                            src={FILE_URL + message.user_que_image}
                            alt="Uploaded"
                            className="mt-2 max-h-80 max-w-xs rounded-lg"
                        />
                    )} 
                </div>
            </div>)}
            {(message.company_response || message.company_res_image) && (<div className={`flex justify-start mb-2`}>
                <div className={`p-2 rounded-lg bg-gray-200`}> 
                    {message.company_response && <p className="max-w-xl mt-2">{message.company_response}</p>}
                    {message.company_res_image && (
                        <img
                            src={FILE_URL + message.company_res_image}
                            alt="Company Response"
                            className="mt-2 max-h-80 max-w-xs rounded-lg"
                        />
                    )}
                </div>
            </div>)}
        </div>
    );
};

export default Message;
