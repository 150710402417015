import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    submitReducer,
    getReducer, 
} from './reducers/allReducers'; 
import { authReducer, userOrdersReducer } from './reducers/authReducers';
import { getInvoiceTypeListReducer, getInvoiceTypeReducer, uploadInvoiceTypeReducer } from './reducers/invoiceReducers';
import { loadingReducer } from './reducers/loadingReducer';

const rootReducer = combineReducers({
    loading: loadingReducer,
    data: submitReducer, 
    getData: getReducer,
    auth: authReducer,
    uploadedInvoiceType: uploadInvoiceTypeReducer, 
    invoiceType: getInvoiceTypeReducer,
    invoiceTypeList: getInvoiceTypeListReducer,
    orders: userOrdersReducer,
});

const initialState = {
    data: [], 
    getData: [],
    auth: { userInfo: null },
    uploadedInvoiceType: {},
    invoiceType: {},
    invoiceTypeList: [],
    orders: { orders: [] },
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

export default store;
