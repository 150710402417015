import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../../Common/CartContext';
import { useDispatch } from 'react-redux';
import { decryptData } from '../../../utils/encryption';
import { addOrderAndPayment } from '../../../redux/actions/orderActions';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import '../../../style/Style.css';
import axiosInstance from '../../../Common/axiosInstance';
import ExcitingMessageModal from './ExcitingMessageModal';

const CheckoutPage = () => {
  const { cart, clearCart } = useContext(CartContext);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const user = decryptData(localStorage.getItem('H$S%#PR32S'));

  const [shippingInfo, setShippingInfo] = useState(() => {
    const savedShippingInfo = sessionStorage.getItem('shippingInfo');
    return savedShippingInfo ? JSON.parse(savedShippingInfo) : {
      user: user.id,
      address: '',
      city: '',
      zip_code: '',
      country: '',
    };
  });

  const [errors, setErrors] = useState({});
  const totalAmount = cart ? cart.reduce((acc, item) => acc + item.price, 0) : 0;

  useEffect(() => {
    if (user && shippingInfo.address === '') {
      fetchShippingAddress();
    }
  }, []);

  useEffect(() => {
    if(user && shippingInfo.address !== '') {
      sessionStorage.setItem('shippingInfo', JSON.stringify(shippingInfo));
    }
  }, [shippingInfo]);

  const fetchShippingAddress = async () => {
    try {
      const response = await axiosInstance.get('/get-shipping-address/');
      setShippingInfo({
        user: user.id,
        address: response.data.address || '',
        city: response.data.city || '',
        zip_code: response.data.zip_code || '',
        country: response.data.country || '',
      });
    } catch (error) {
      console.error('Error fetching shipping address', error);
    }
  };

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!shippingInfo.address) newErrors.address = 'Address is required';
    if (!shippingInfo.city) newErrors.city = 'City is required';
    if (!shippingInfo.zip_code) newErrors.zip_code = 'Zip Code is required';
    if (!shippingInfo.country) newErrors.country = 'Country is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePlaceOrder = async (details) => {
    try {
      const orderData = {
        shippingInfo: shippingInfo,
        products: cart,
        totalAmount,
        payment_status: 'paid',
        payment: {
          amount: totalAmount,
          payment_method: 'paypal',
          payment_status: 'paid',
          response: details,
        }
      };
      const response = await dispatch(addOrderAndPayment(orderData));
      if (response && response.success) {
        clearCart(); // Clear cart after successful order
        setIsModalOpen(true); // Show modal with success message
      } else {
        setErrorMessage('Payment failed. Please try again.');
        console.error('Order or payment creation failed', response);
      }
    } catch (error) {
      setErrorMessage('Payment failed. Please try again.');
      console.error('Order or payment creation failed', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-10">
      <div className="container mx-auto p-4">
        {errorMessage && (
          <div className="bg-red-100 border-t-4 border-red-400 text-red-700 px-4 py-4 rounded relative mb-4 shadow-md" role="alert">
            <strong className="font-bold">Payment Error! </strong>
            <span className="block sm:inline">{errorMessage}</span>
            <button
              onClick={() => setErrorMessage('')}
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
            >
              <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </button> 
          </div>
        )}
        <nav className="text-gray-700 mb-4">
          <Link to="/" className="text-gray-700 font-semibold">Home</Link> / <span className="text-blue-700 font-semibold">Checkout</span>
        </nav>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <div className="col-span-8 bg-white p-8 rounded-lg shadow-md">
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">Billing Address</h3>
              <form className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className='mt-2'>
                    <input
                      type="text"
                      name="address"
                      value={shippingInfo.address}
                      placeholder="Address"
                      maxLength={300}
                      onChange={handleShippingChange}
                      className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.address ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                    />
                    {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                  </div>
                  <div className='mt-2'>
                    <input
                      type="text"
                      name="city"
                      value={shippingInfo.city}
                      placeholder='City'
                      maxLength={50}
                      onChange={handleShippingChange}
                      className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.city ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                    />
                    {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className='mt-2'>
                    <input
                      type="text"
                      name="zip_code"
                      value={shippingInfo.zip_code}
                      placeholder='Zip Code'
                      maxLength={10}
                      onChange={handleShippingChange}
                      className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.zip_code ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'}`}
                    />
                    {errors.zip_code && <p className="text-red-500 text-sm">{errors.zip_code}</p>}
                  </div>
                  <div className='mt-2'>
                    <input
                      type="text"
                      name="country"
                      value={shippingInfo.country}
                      placeholder='Country'
                      maxLength={50}
                      onChange={handleShippingChange}
                      className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.country ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                    />
                    {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
                  </div>
                </div>
              </form>
            </div>
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">Payment Method</h3> 
              <div className="block text-gray-700 font-semibold flex items-center justify-center">
                <PayPalScriptProvider options={{ "client-id": "AWqlapH5xllqOy1beMWDvGdny3Z0fMa5x1bz_t2BbG1EhVewewvnze0qLfFSIw2d4un4C8BsRxqDT7zG" }}>
                  <PayPalButtons
                    style={{ layout: "vertical", color: "blue", shape: "pill", label: "pay"}}
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: cart.map(item => ({
                          reference_id: item.id.toString(),
                          amount: {
                            currency_code: "USD",
                            value: item.price.toFixed(2)
                          },
                          description: item.name,
                        }))
                      });
                    }}
                    onApprove={(data, actions) => { 
                      return actions.order.capture().then((details) => {
                        handlePlaceOrder(details);
                      });
                    }}
                    onError={(err) => {
                      setErrorMessage('Payment failed. Please try again.');
                      console.error('Payment error', err);
                    }}
                  />
                </PayPalScriptProvider>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Order Summary</h3>
              <div className="space-y-4">
                {cart.map((item) => (
                  <div key={item.id} className="flex justify-between">
                    <div>
                      <p className="font-semibold text-gray-700">{item.name}</p>
                      <p className="text-gray-500">License: {item.license}</p>
                    </div>
                    <p className="font-semibold text-gray-700">${item.price}</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-between mt-4 border-t pt-4">
                <p className="font-semibold text-gray-700">Total</p>
                <p className="font-semibold text-gray-700">${totalAmount.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <ExcitingMessageModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default CheckoutPage;
